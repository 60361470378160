import { useField } from 'formik';
import React, { Fragment, useState } from 'react'
import * as queries from "../../../graphql/queries";
import { User, UserRole } from '../../../graphql/schemaTypes';
import { Maybe } from 'yup';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Combobox, Transition } from '@headlessui/react';

interface Props {
    userRole: UserRole,
    label?: string,
    fieldName: string,
    user?: User,
    onSelect?: (v?: User) => void,
    searchByFeild?: "name" | "phoneNumber" | "email"
}

function AutoCompleteField({ fieldName, userRole, onSelect, label, searchByFeild = "name" }: Props) {
    const [userObjectFieldName, userFieldName] = fieldName.split('.');
    const [users, setUsers] = useState<User[]>([]);
    const [field, meta, helpers] = useField<string | undefined>({ name: fieldName });
    const [{ }, _meta, _helpers] = useField({ name: userObjectFieldName });
    // const [field, meta, helpers] = useField({ name: "" });

    const searchUser = async (value: string) => {
        helpers.setValue(value)
        const { data } = await queries.searchUsers({
            input: {
                role: userRole,
                [searchByFeild]: value
            }
        });
        const plugUsers = data?.searchUsers as User[] || [];
        setUsers(plugUsers)
    }

    const handleSelect = (u: User) => {
      _helpers.setValue({ ...u, selected: true });
      onSelect?.(u);
    };

    return (
      <Combobox
        value={_meta.value}
        onChange={handleSelect}
        disabled={_meta.value?.selected || _meta.value?.isOnboarded}
      >
        <div className="relative w-full">
          <div className="relative w-full cursor-default rounded-lg">
            <Combobox.Input
              placeholder={label}
              className="w-full text-gray-900 px-1 text-[12px]"
              displayValue={(user: User) => user?.[userFieldName] || ""}
              onChange={(event) => searchUser(event.target.value)}
            />
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            // afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute z-10 mt-1 max-h-32 overflow-y-auto w-auto rounded-md bg-white shadow-lg ring-1 ring-black/5 text-[12px]">
              {users.length === 0 ? (
                <div className="cursor-default select-none py-2 px-4 text-gray-700">
                  No user found.
                </div>
              ) : (
                users.map((user) => (
                  <Combobox.Option
                    key={user.id}
                    value={user}
                    className={({ active }) =>
                      ` cursor-default select-none py-1.5 px-4 text-left text-[12px] ${
                        active ? "bg-blue-100" : "text-gray-900"
                      }`
                    }
                  >
                    <b>
                      {user.firstName} {user.lastName}
                    </b>
                    <span className="border border-slate-600 text-[10px] rounded-xl p-1 m-1">
                      {user.role?.toUpperCase()}
                    </span>
                    <br />
                    {user.email}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    );
}

export default AutoCompleteField
