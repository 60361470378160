import React from "react";
import { useNavigate } from "react-router-dom";

import { PAGES } from "../../app-config";
import { registerTeam } from "../../redux/features/team.slice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import TeamForm, { FormFields as TeamFormFields } from "./components/TeamForm";

const RegistrationPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const showAlert = useAppSelector((s) => s.alerts.showAlert);
  const showDialog = useAppSelector((s) => s.alerts.showDialog);
  const selectedEventId = useAppSelector((s) => s.team.selectedEventId);

  const handleTeamRegistration = async ({
    name,
    coaches,
    players,
  }: TeamFormFields) => {
    const { err, res } = await new Promise<{ err; res }>((resolve, reject) => {
      dispatch(
        registerTeam({
          input: {
            eventId: selectedEventId,
            name,
            coaches,
            players,
          },
          callback: (err, res) => {
            resolve({ err, res });
          },
        })
      );
    });

    if (res) {
      showAlert({ message: "Registeration completed", severity: "success" });
      navigate(`/${PAGES.home.children.teams.path}`);
    }
    if (err)
      showDialog("error", "Registration Error", err || "Something went wrong.");
  };

  return (
    <div className="">
      <h1 className="text-2xl font-semibold">
        {PAGES.home.children.registration.title}
      </h1>
      <br />
      <TeamForm type="Create" onSubmit={handleTeamRegistration} />
    </div>
  );
};

export default RegistrationPage;
