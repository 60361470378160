import { FC } from "react";
import * as Auth from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";

import { PAGES } from "../../app-config";
import AboutYou from "./components/aboutYou";
import Loader from "../../components/loader";
import AboutTeam from "./components/aboutTeam";
import { DIVISION_LIST } from "../../util/constants";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { createUserMutation } from "../../redux/features/auth.slice";

const Onboarding: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const createUserData = useAppSelector((s) => s.auth.createUserData);
  const loading = useAppSelector(
    (s) => s.auth.fetchingStatus.createUserMutation
  );

  const isDataIncomplete = (): boolean => {
    if (!createUserData) return true; // Return true if createUserData is null
    for (const [key, value] of Object.entries(createUserData)) {
      if (value === null || value === "") {
        return true;
      }
      if (key === 'organization') {
        if (value.name === null || value.name === "") {
          return true;
        }
      }
    }
    if (
      createUserData?.class === DIVISION_LIST[0] ||
      createUserData?.class === DIVISION_LIST[1]
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleCompleteOnboarding = async () => {
    try {
      const userData = await Auth.fetchUserAttributes();
      await dispatch(
        createUserMutation({
          input: {
            ...createUserData,
            email: userData?.email,
            phoneNumber: userData?.phone_number,
          },
        })
      );
      navigate(PAGES.home.path, { replace: true });
    } catch (error) {
      // alert(error?.message || "An error occurred during onboarding.");
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-white mt-24 p-4">
      <Loader open={loading}/>
      <AboutYou />
      <AboutTeam />
      <div className="flex justify-center mt-36 mb-24">
        <button
          className="text-white py-2 px-4 rounded-full bg-main-primary w-80 font-bold"
          style={{
            backgroundColor: isDataIncomplete() ? "#DEDEE3" : "#1889DB",
            color: isDataIncomplete() ? "#747478" : "white",
          }}
          disabled={isDataIncomplete()}
          onClick={handleCompleteOnboarding}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Onboarding;
