import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  GetTeamsInput,
  Team,
  Event,
  EventsList,
} from "../../../graphql/schemaTypes";
import { handlePending, handleReject } from "../../helper";
import {
  getEvents,
  getTeams,
  inviteUserToTeam,
  registerTeam,
  updateTeam,
} from "./asyncThunk";
import { signOut } from "../auth.slice";

interface StateType {
  teamsList: { items: Team[] | null; count: number; input: GetTeamsInput };
  eventsList: EventsList;
  selectedEventId: string;
  fetchingStatus: {
    registerTeam: boolean;
    updateTeam: boolean;
    getTeams: boolean;
    getEvents: boolean;
    inviteUserToTeam: boolean;
  };
  error: {
    registerTeam: null | string;
    updateTeam: null | string;
    getTeams: null | string;
    getEvents: null | string;
    inviteUserToTeam: null | string;
  };
}

const initialState: StateType = {
  fetchingStatus: {
    registerTeam: false,
    updateTeam: false,
    getTeams: false,
    getEvents: false,
    inviteUserToTeam: false,
  },
  error: {
    registerTeam: null,
    updateTeam: null,
    getTeams: null,
    getEvents: null,
    inviteUserToTeam: null,
  },
  teamsList: {
    items: null,
    count: 0,
    input: { page: { pgNo: 1, pgSize: 10 } },
  },
  eventsList: { items: [], count: 0 },
  selectedEventId: "",
};

const slice = createSlice({
  name: "team",
  initialState,
  reducers: {
    updateSelectedEventId: (state, { payload }: PayloadAction<any>) => {
      state.selectedEventId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOut.fulfilled, (state, { payload }) => {
      return initialState;
    });

    builder.addCase(getTeams.fulfilled, (state, { payload, meta: { arg } }) => {
      if (!payload) {
        return;
      }
      //  update state registrationData
      state.fetchingStatus.getTeams = false;
      state.error.getTeams = null;
      state.teamsList.items = (payload.items as Team[]) || [];
      state.teamsList.count = payload.total;
      state.teamsList.input = {
        ...state.teamsList.input,
        ...arg.input,
      };
    });
    builder.addCase(getTeams.pending, handlePending("getTeams"));
    builder.addCase(getTeams.rejected, handleReject("getTeams"));

    builder.addCase(
      registerTeam.fulfilled,
      (state, { payload, meta: { arg } }) => {
        if (!payload) {
          return;
        }
        //  update state registrationData
        state.fetchingStatus.registerTeam = false;
        state.error.registerTeam = null;
        payload.team && state.teamsList.items?.pop();
        payload.team && state.teamsList.items?.unshift(payload.team);
        state.teamsList.count++;
        arg.callback?.(null, payload);
      }
    );
    builder.addCase(registerTeam.pending, handlePending("registerTeam"));
    builder.addCase(registerTeam.rejected, handleReject("registerTeam"));

    builder.addCase(
      updateTeam.fulfilled,
      (state, { payload, meta: { arg } }) => {
        if (!payload) {
          return;
        }
        //  update state registrationData
        state.fetchingStatus.updateTeam = false;
        state.error.updateTeam = null;
        const teamIndex = Number(
          state.teamsList.items?.findIndex((t) => t.id === payload.id)
        );
        if (teamIndex >= 0 && state.teamsList.items) {
          state.teamsList.items[teamIndex] = payload;
        }
        arg.callback?.(null, payload);
      }
    );
    builder.addCase(updateTeam.pending, handlePending("updateTeam"));
    builder.addCase(updateTeam.rejected, handleReject("updateTeam"));

    builder.addCase(
      getEvents.fulfilled,
      (state, { payload, meta: { arg } }) => {
        if (!payload) {
          return;
        }
        state.fetchingStatus.getEvents = false;
        state.error.getEvents = null;
        state.eventsList.items = (payload.items as Event[]) || [];
        state.eventsList.count = payload.count;
      }
    );
    builder.addCase(getEvents.pending, handlePending("getEvents"));
    builder.addCase(getEvents.rejected, handleReject("getEvents"));

    builder.addCase(
      inviteUserToTeam.fulfilled,
      (state, { payload, meta: { arg } }) => {
        if (!payload) {
          return;
        }
        const teamIndex = Number(
          state.teamsList.items?.findIndex((t) => t.id === arg.input.teamId)
        );
        if (teamIndex >= 0 && state.teamsList.items) {
          const team = state.teamsList.items[teamIndex];
          const players = team.players;
          const coaches = team.coaches;

          if (players) {
            const playerIndex = players.findIndex(
              (player) => player.info.id === arg.input.inviteeId
            );
            if (playerIndex !== -1) {
              players[playerIndex].inviteSent = true;
            }
          }
          if (coaches) {
            const coachIndex = coaches.findIndex(
              (coach) => coach?.info.id === arg.input.inviteeId
            );
            if (coachIndex !== -1 && coaches[coachIndex] !== undefined) {
              coaches[coachIndex]!!.inviteSent = true;
            }
          }
        }
        state.fetchingStatus.inviteUserToTeam = false;
        state.error.inviteUserToTeam = null;
        arg.callback?.(null, payload);
      }
    );
    builder.addCase(
      inviteUserToTeam.pending,
      handlePending("inviteUserToTeam")
    );
    builder.addCase(
      inviteUserToTeam.rejected,
      handleReject("inviteUserToTeam")
    );
  },
});

export default slice.reducer;
export const { updateSelectedEventId } = slice.actions;

export { getTeams, registerTeam, updateTeam, getEvents, inviteUserToTeam };
