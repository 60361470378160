import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";

import { terms_modal, privacy_modals } from "../util/constants";

interface IAuthModal {
  modalType: "terms" | "privacy";
  handleClose: () => void;
}
const AuthModal: React.FC<IAuthModal> = ({ modalType, handleClose }) => {
  const modalText = modalType === "terms" ? terms_modal : privacy_modals;

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(255, 255, 255, 0.9)", 
        },
      }}
    >
      <DialogTitle>
        {modalType === "terms" ? "Terms and Conditions" : "Privacy Policy"}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ padding: 2 }}>
          <Typography style={{ whiteSpace: "pre-line" }}>
            {modalText}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthModal;
