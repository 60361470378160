import React, { ButtonHTMLAttributes, FC, useEffect, useState } from "react";

interface PaginationProps {
  count: number;
  /**
   * default page sizes list [10, 25, 50, 100]
   */
  pageSizes?: number[];
  defaultPageSize?: number;
  defaultPage?: number;
  onChange?: (pageNumber: number, pageSize: number) => void;
}

const _pageSizes = [10, 25, 50, 100];
const numberOfPageButtonsToShow = 3; // should be odd number

const Pagination: FC<PaginationProps> = ({
  pageSizes = _pageSizes,
  count,
  defaultPage = 1,
  defaultPageSize,
  onChange,
}) => {
  const [pageNumber, setPageNumber] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize || pageSizes[0]);
  const [numberOfPages, setNumberOfPages] = useState(
    Math.ceil(count / pageSize)
  );
  const [pageButtonNumbers, setPageButtonNumber] = useState<number[]>([]);

  useEffect(() => {
    let _pageButtonNumbers: number[];
    let diff = 0;
    let _numberOfPageButtonsToShow = numberOfPageButtonsToShow;
    if (numberOfPageButtonsToShow > numberOfPages) {
      _numberOfPageButtonsToShow = numberOfPages;
    }
    if (numberOfPages < pageNumber + _numberOfPageButtonsToShow) {
      diff = pageNumber + _numberOfPageButtonsToShow - (numberOfPages + 1);
    }

    _pageButtonNumbers = Array(_numberOfPageButtonsToShow)
      .fill(1)
      .map((_, idx) => idx + pageNumber - diff);
    setPageButtonNumber(_pageButtonNumbers);
    onChange && onChange(pageNumber, pageSize);
  }, [pageNumber, pageSize, numberOfPages]);

  useEffect(() => {
    moveToFirstPage();
    setNumberOfPages(Math.ceil(count / pageSize));
  }, [pageSize, count]);

  const handlePageNumberChange = (pageNumber: number) => () => {
    setPageNumber(pageNumber);
  };
  const moveToFirstPage = () => {
    setPageNumber(1);
  };
  const moveToLastPage = () => {
    setPageNumber(numberOfPages);
  };
  const increasePage = () => {
    pageNumber < numberOfPages && setPageNumber(pageNumber + 1);
  };
  const decreasePage = () => {
    pageNumber > 1 && setPageNumber(pageNumber - 1);
  };

  return (
    <div className="flex justify-between flex-wrap">
      <div
        role="button-group"
        className="flex space-x-2 items-center justify-start flex-wrap"
      >
        <Btn disabled={pageNumber <= 1} onClick={moveToFirstPage}>
          {"<<"}
        </Btn>
        <Btn disabled={pageNumber <= 1} onClick={decreasePage}>
          Previous
        </Btn>
        {pageButtonNumbers.map((page, idx) => {
          return (
            <Btn
              key={idx}
              selected={page === pageNumber}
              onClick={handlePageNumberChange(page)}
            >
              {page}
            </Btn>
          );
        })}
        <Btn disabled={pageNumber >= numberOfPages} onClick={increasePage}>
          Next
        </Btn>
        <Btn disabled={pageNumber >= numberOfPages} onClick={moveToLastPage}>
          {">>"}
        </Btn>
      </div>

      <div className="my-auto">
        Page {pageNumber} of {numberOfPages}, count {count}
      </div>

      <div role="page-size-select" className="my-auto">
        <Select
          // className='m-2'
          defaultValue={pageSize}
          options={pageSizes.map((v) => ({ value: v, label: `${v} items` }))}
          onChange={(e) => {
            setPageSize(Number(e.target.value) || 1);
          }}
        />
      </div>
    </div>
  );
};

export default Pagination;

const Btn: FC<
  ButtonHTMLAttributes<HTMLButtonElement> & { selected?: boolean }
> = ({ children, disabled, className, selected, ...other }) => {
  return (
    <button
      disabled={disabled}
      className={
        `
            p-2 px-2.5 my-2 text-[.9rem] text-center rounded-md border-none shadow-md shadow-slate-400 active:shadow-md
            ${
              selected
                ? "bg-[#b0d3f0] text-[#245175] "
                : "bg-gray-300 text-gray-600 hover:bg-gray-400"
            }
            ${disabled ? "cursor-not-allowed shadow-none" : ""}
        ` + className
      }
      {...other}
    >
      {children}
    </button>
  );
};

interface SelectProps {
  options: { label: string; value: string | number }[];
  defaultValue?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}
function Select({ options, defaultValue, onChange }: SelectProps) {
  return (
    <select
      defaultValue={defaultValue}
      onChange={onChange}
      className="p-1.5 bg-gray-300 text-gray-600"
    >
      {options.map((v, idx) => {
        return (
          <option key={idx} value={v.value}>
            {v.label}
          </option>
        );
      })}
    </select>
  );
}
