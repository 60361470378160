import { FC, Fragment } from "react";
import { Dialog as TDialog, Transition } from "@headlessui/react";
import {
  InformationCircleIcon,
  XCircleIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";
import Button from "../button";

export const color = {
  error: "bg-red-500",
  success: "bg-green-500",
  warning: "bg-orange-500",
  confirmation: "bg-blue-500",
  info: "bg-blue-400",
};
export const icon = {
  error: <XCircleIcon color="white" width={50} height={50} />,
  success: <CheckCircleIcon color="white" width={50} height={50} />,
  warning: <ExclamationCircleIcon color="white" width={50} height={50} />,
  confirmation: <QuestionMarkCircleIcon color="white" width={50} height={50} />,
  info: <InformationCircleIcon color="white" width={50} height={50} />,
};

export type Severity = keyof typeof color;

export type DialogResponse = "ok" | "yes" | "no" | "nothing";

export interface DialogStateType {
  open: boolean;
  severity: Severity;
  title: string;
  message: string;
  callback: (val: DialogResponse) => void;
}

interface DialogProps {
  open: boolean;
  severity: Severity;
  title: string;
  message: string;
  onResponse: (val: DialogResponse) => void;
}

export const Dialog: FC<DialogProps> = ({
  open,
  severity,
  title,
  message,
  onResponse,
}) => {
  function closeModal() {
    onResponse("nothing");
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <TDialog
        as="div"
        className="fixed inset-0 z-[1000000] overflow-y-auto bg-[rgba(0,0,0,.4)] "
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <TDialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
              <div className={`p-6 ${color[severity]} border-b-2 border-black`}>
                <div className="flex items-center">
                  <span>{icon[severity]}</span>
                  <h3 className="text-lg font-medium leading-6 text-white font-sans">
                    {title}
                  </h3>
                </div>
                <div className="mt-2.5">
                  <p className="text-sm text-white">{message}</p>
                </div>
              </div>

              <div className="mt-4 flex justify-end p-4 space-x-2">
                {(
                  ["error", "warning", "success", "info"] as Severity[]
                ).includes(severity) && (
                  <Button
                    onClick={() => {
                      onResponse("ok");
                    }}
                  >
                    OK
                  </Button>
                )}
                {(["confirmation"] as Severity[]).includes(severity) && (
                  <Button
                    onClick={() => {
                      onResponse("yes");
                    }}
                  >
                    Yes
                  </Button>
                )}
                {(["confirmation"] as Severity[]).includes(severity) && (
                  <Button
                    onClick={() => {
                      onResponse("no");
                    }}
                  >
                    No
                  </Button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </TDialog>
    </Transition>
  );
};
export default Dialog;
