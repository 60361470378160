import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ShowDialogCallback } from "../../../components/dialogAndAlerts";
import { AlertStateType } from "../../../components/dialogAndAlerts/alert";
import { HeaderAlertStateType } from "../../../components/dialogAndAlerts/headerAlert";

export type AuthState = "SIGNED_IN" | "SIGNED_OUT" | "PENDING";

type StateType = {
  showDialog: ShowDialogCallback;
  showAlert: (val: Omit<AlertStateType, "id">) => void;
  showHeaderAlert: (val: Omit<HeaderAlertStateType, "id">) => void;
};

const initialState: StateType = {
  showDialog: async (severity, title, message, onResponse) => {
    return "ok";
  },
  showAlert: (val) => {
    console.log(val);
  },
  showHeaderAlert: (val) => {
    console.log(val);
  },
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setDialogHandler: (state, { payload }: PayloadAction<StateType>) => {
      state.showAlert = payload.showAlert;
      state.showDialog = payload.showDialog;
      state.showHeaderAlert = payload.showHeaderAlert;
    },
  },
  extraReducers: (builder) => {},
});

export const { setDialogHandler } = slice.actions;

export default slice.reducer;
