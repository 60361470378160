export const PROD_EVENT_ID = "event-102a9c2c-2043-445d-ba54-82e4cb463e1b";
export const DEV_EVENT_ID = "event-086482ed-6fc5-4b7d-9e30-6efa97b8d7a4";
const WRESTLING_POSITIONS = [
  { label: "Freestyle", value: "Freestyle" },
  { label: "Folkstyle", value: "Folkstyle" },
];

const SOCCER_POSITIONS = [
  { label: "Goal Keeper", value: "Goal Keeper" },
  { label: "Right Fullback", value: "Right Fullback" },
  { label: "Left Fullback", value: "Left Fullback" },
  { label: "Center Back", value: "Center Back" },
  { label: "Defender Midfielder", value: "Defender Midfielder" },
  { label: "Right Midfielder", value: "Right Midfielder" },
  { label: "Center Midfielder", value: "Center Midfielder" },
  { label: "Forward", value: "Forward" },
  { label: "Attacking Midfielder", value: "Attacking Midfielder" },
  { label: "Left Midfielder", value: "Left Midfielder" },
];
const BASKETBALL_POSITIONS = [
  { label: "Center", value: "Center" },
  { label: "Power Forward", value: "Power Forward" },
  { label: "Point Guard", value: "Point Guard" },
  { label: "Shooting Guard", value: "Shooting Guard" },
  { label: "Small Forward", value: "Small Forward" },
];
const FOOTBALL_POSITIONS = [
  { label: "Quarterback", value: "Quarterback" },
  { label: "Running Back", value: "Running Back" },
  { label: "Fullback", value: "Fullback" },
  { label: "Offensive Line", value: "Offensive Line" },
  { label: "Wide Receiver", value: "Wide Receiver" },
  { label: "Tight End", value: "Tight End" },
  { label: "Defensive Line", value: "Defensive Line" },
  { label: "Linebacker", value: "Linebacker" },
  { label: "Cornerback", value: "Cornerback" },
  { label: "Safety", value: "Safety" },
  { label: "Kicker", value: "Kicker" },
  { label: "Punter", value: "Punter" },
  { label: "Return Specialist", value: "Return Specialist" },
  { label: "Long Snapper", value: "Long Snapper" },
];
const HOCKEY_POSITIONS = [
  { label: "Goalie", value: "Goalie" },
  { label: "Left Defense", value: "Left Defense" },
  { label: "Left Wing", value: "Left Wing" },
  { label: "Center", value: "Center" },
  { label: "Right Wing", value: "Right Wing" },
  { label: "Right Defense", value: "Right Defense" },
];
const VOLLEYBALL_POSITIONS = [
  { label: "Setter", value: "Setter" },
  { label: "Outside Hitter", value: "Outside Hitter" },
  { label: "Opposite Hitter", value: "Opposite Hitter" },
  { label: "Middle Blocker", value: "Middle Blocker" },
  { label: "Libero", value: "Libero" },
  { label: "Defensive Specialist", value: "Defensive Specialist" },
];
const BASEBALL_POSITIONS = [
  { label: "Left Field", value: "Left Field" },
  { label: "Center Field", value: "Center Field" },
  { label: "Right Field", value: "Right Field" },
  { label: "Third Base", value: "Third Base" },
  { label: "Short Stop", value: "Short Stop" },
  { label: "Second Base", value: "Second Base" },
  { label: "First Base", value: "First base" },
  { label: "Pitcher", value: "Pitcher" },
  { label: "Catcher", value: "Catcher" },
];
const TRACK_POSITIONS = [
  { label: "100 m", value: "100 m" },
  { label: "200 m", value: "200 m" },
  { label: "400 m", value: "400 m" },
  { label: "800 m ", value: "800 m" },
  { label: "1500 m", value: "1500 m" },
  { label: "3000 m", value: "3000 m" },
  { label: "Steeplechase", value: "Steeplechase" },
  { label: "5000 m", value: "5000 m" },
  { label: "10,000 m ", value: "10,000 m " },
  { label: "400 m Hurdles", value: "400 m Hurdles" },
  { label: "4x100 m Relay", value: "4x100 m Relay" },
  { label: "Long Jump", value: "Long Jump" },
  { label: "Pole Vault", value: "Pole Vault" },
  { label: "Triple Jump", value: "Triple Jump" },
  { label: "High Jump", value: "High Jump" },
  { label: "Discus", value: "Discus" },
  { label: "Shot Put", value: "Shot Put" },
  { label: "Javelin", value: "Javelin" },
  { label: "Hammer Throw", value: "Hammer Throw" },
  { label: "4x400 m Relay", value: "4x400 m Relay" },
];

export const getPositionList = (sport) => {
  switch (sport) {
    case "Baseball":
      return BASEBALL_POSITIONS;
    case "Basketball":
      return BASKETBALL_POSITIONS;
    case "Football":
      return FOOTBALL_POSITIONS;
    case "Hockey":
      return HOCKEY_POSITIONS;
    case "Volleyball":
      return VOLLEYBALL_POSITIONS;
    case "Softball":
      return BASEBALL_POSITIONS;
    case "Track & Field":
      return TRACK_POSITIONS;
    case "Soccer":
      return SOCCER_POSITIONS;
    case "Wrestling":
      return WRESTLING_POSITIONS;
    default:
      return [];
  }
};
function GetYears(startYear: number, endYear: number): { label: string; value: string }[] {
  var items: { label: string; value: string }[] = [];
  for (var i = startYear; i <= endYear; i++) {
      var val = i.toString();
      items.push({ label: val, value: val });
  }
  return items;
}
export const GRAD_YEAR = GetYears(2020, 2040);
const GetWeights = (min: number, max: number): { label: string; value: string }[] => {
  var items: { label: string; value: string }[] = [];
  for (var i = min; i < max; i++) {
    var val = `${i} lbs`;
    items.push({ label: val, value: val });
  }
  return items;
};
export const WEIGHT = GetWeights(70, 300);
export const HEIGHT = [
  { label: "4'0\"", value: "4'0\"" },
  { label: "4'1\"", value: "4'1\"" },
  { label: "4'2\"", value: "4'2\"" },
  { label: "4'3\"", value: "4'3\"" },
  { label: "4'4\"", value: "4'4\"" },
  { label: "4'5\"", value: "4'5\"" },
  { label: "4'6\"", value: "4'6\"" },
  { label: "4'7\"", value: "4'7\"" },
  { label: "4'8\"", value: "4'8\"" },
  { label: "4'9\"", value: "4'9\"" },
  { label: "4'10\"", value: "4'10\"" },
  { label: "4'11\"", value: "4'11\"" },
  { label: "5'0\"", value: "5'0\"" },
  { label: "5'1\"", value: "5'1\"" },
  { label: "5'2\"", value: "5'2\"" },
  { label: "5'3\"", value: "5'3\"" },
  { label: "5'4\"", value: "5'4\"" },
  { label: "5'5\"", value: "5'5\"" },
  { label: "5'6\"", value: "5'6\"" },
  { label: "5'7\"", value: "5'7\"" },
  { label: "5'8\"", value: "5'8\"" },
  { label: "5'9\"", value: "5'9\"" },
  { label: "5'10\"", value: "5'10\"" },
  { label: "5'11\"", value: "5'11\"" },
  { label: "6'0\"", value: "6'0\"" },
  { label: "6'1\"", value: "6'1\"" },
  { label: "6'2\"", value: "6'2\"" },
  { label: "6'3\"", value: "6'3\"" },
  { label: "6'4\"", value: "6'4\"" },
  { label: "6'5\"", value: "6'5\"" },
  { label: "6'6\"", value: "6'6\"" },
  { label: "6'7\"", value: "6'7\"" },
  { label: "6'8\"", value: "6'8\"" },
  { label: "6'9\"", value: "6'9\"" },
  { label: "6'10\"", value: "6'10\"" },
  { label: "6'11\"", value: "6'11\"" },
];

export const checkPhoneAndEmail = (data) => {
  const allFilled = data.every((item) => {
    if (item.selected || item.isOnboarded) {
      return item.email
    }
    return item.email && item.phoneNumber
  });
  return allFilled;
};
export const hasDuplicateJerseyNumbers = (players) => {
  const jerseyNumbers = new Set();
  for (const player of players) {
    if (player.jerseyNumber && jerseyNumbers.has(player.jerseyNumber)) {
      return true;
    }
    if (player.jerseyNumber) {
      jerseyNumbers.add(player.jerseyNumber);
    }
  }
  return false;
};

export const hasDuplicatePhoneNumbers = (players) => {
  const phoneNumbers = new Set();
  for (const player of players) {
    if (player.phoneNumber && phoneNumbers.has(player.phoneNumber)) {
      return true;
    }
    if (player.phoneNumber) {
      phoneNumbers.add(player.phoneNumber);
    }
  }
  return false;
};

export const hasDuplicateEmails = (players) => {
  const emails = new Set();
  for (const player of players) {
    if (player.email && emails.has(player.email)) {
      return true;
    }
    if (player.email) {
      emails.add(player.email);
    }
  }
  return false;
};