export const getAddress = async (zipcode: string) => {
  try {
    const response = await fetch("https://api.zippopotam.us/us/" + zipcode);
    const result = await response.json();
    if (result.places) {
      return [result.places[0].state, result.places[0]["place name"]];
    }
    alert("Invalid zipcode. Please enter a valid zipcode.");
    return [];
  } catch (error) {
    alert("Invalid zipcode. Please enter a valid zipcode.");
  }
};
