import { useEffect, useState } from "react";
import { CheckIcon, MinusIcon } from "@heroicons/react/24/outline";

import Pagination from "../pagination";
export { default as Filter } from "../filter";

interface Column<RowValues = { [k: string]: any }> {
  accessKey: keyof RowValues;
  label: string;
  minWidth?: number;
  maxWidth?: number;
  width?: number;
  align?: "right" | "inherit" | "left" | "center" | "justify";
  format?: (
    cellValue: any,
    rowValues: RowValues,
    tableRowIndex: number
  ) => string | number | JSX.Element | undefined | null;
}

interface TableProps<RowValues> {
  label?: JSX.Element;
  hideFooter?: boolean;
  columns: Column<RowValues>[];
  rows: RowValues[];
  /**
   * this will be used when there is an empty array of row
   */
  emptyRowMsg?: React.ReactNode;
  totalRows?: number;
  // onChangeRowsPerPage?: (pageNumber: number, pageSize: number) => void,
  // onChangePageNo?: (pageNumber: number, pageSize: number) => void,
  onPaginationChange?: (pageNumber: number, pageSize: number) => void;
  pageNumber?: number;
  pageSize?: number;
  loading?: boolean;
  /**
   * when data will be fetching asynchronously on pageNumber and pageSize change then it should be true
   */
  dataFetchingAsync?: boolean;
  onClickRow?: (
    RowIndex: number,
    columnIndex: number,
    cellValue: RowValues[keyof RowValues],
    rowValues: RowValues
  ) => void;
  enableCheckboxSelection?: boolean;
  onRowSelection?: (
    selectedRowsIndex: number[],
    selectedRows: RowValues[]
  ) => void;
}

export default function Table<T = { [k: string | number]: any }>({
  columns,
  onClickRow,
  hideFooter,
  label,
  rows,
  dataFetchingAsync,
  emptyRowMsg,
  loading,
  onPaginationChange,
  pageNumber,
  pageSize,
  totalRows,
  enableCheckboxSelection,
  onRowSelection,
}: TableProps<T>) {
  const [page, setPage] = useState<number>(pageNumber || 1);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize || 100);
  const [render, setRender] = useState(false);

  useEffect(() => {
    pageNumber !== undefined && setPage(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    setSelectedRows([]);
  }, [
    rows?.[0]?.[columns?.[0]?.accessKey],
    rows?.[rows.length - 1]?.[columns?.[0]?.accessKey],
  ]);
  useEffect(() => {
    if (!dataFetchingAsync) {
      rows = rows.slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage
      );
    }
    const selectedRowValues = rows.filter((r, idx) =>
      selectedRows.includes(idx)
    );
    onRowSelection?.(selectedRows, selectedRowValues);
  }, [selectedRows]);

  if (!dataFetchingAsync) {
    rows = rows.slice(
      (page - 1) * rowsPerPage,
      (page - 1) * rowsPerPage + rowsPerPage
    );
  }

  const _onPaginationChange = (pageNumber: number, pageSize: number) => {
    if (render === false) return;
    setPage(pageNumber);
    setRowsPerPage(pageSize);
    onPaginationChange && onPaginationChange(pageNumber, pageSize);
  };

  const handleRowSelection = (rowIndex: number, isSelected: boolean) => {
    isSelected
      ? setSelectedRows((v) => v.filter((v) => v !== rowIndex))
      : setSelectedRows((v) => [...v, rowIndex]);
  };
  const handleRowAllSelection = (allSelected: boolean) => {
    allSelected
      ? setSelectedRows([])
      : setSelectedRows(
        Array(rows.length)
          .fill(1)
          .map((_, i) => i)
      );
  };

  useEffect(() => {
    setRender(true);
  }, []);

  return (
    <div className="">
      {/* client-side-class */ <span className="hover:bg-slate-200"></span>}
      {label}
      <table className="table-fixed">
        <thead className="bg-gray-500 text-white">
          <tr>
            {enableCheckboxSelection &&
              // CheckIcon, MinusIcon
              (() => {
                const checked =
                  selectedRows.length > 0 &&
                  selectedRows.length === rows.length;
                const indeterminate =
                  selectedRows.length > 0 && selectedRows.length < rows.length;
                return (
                  <th className="p-1">
                    <div
                      onClick={() => handleRowAllSelection(checked)}
                      className="bg-white w-fit border border-gray-500 cursor-pointer"
                    >
                      {!checked && !indeterminate && (
                        <CheckIcon className="w-4 invisible" />
                      )}
                      {checked && <CheckIcon className="w-4 text-black" />}
                      {indeterminate && (
                        <MinusIcon className="w-4 text-black" />
                      )}
                    </div>
                  </th>
                );
              })()}
            {columns.map((column, idx) => {
              const { width, maxWidth, minWidth } = column;
              return (
                <th
                  key={idx}
                  className="overflow-auto resize-x p-2 text-left"
                  style={{ width, maxWidth, minWidth }}
                >
                  {column.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-sm">
          {rows.map((row, rowIdx) => {
            const isRowSelected = selectedRows.includes(rowIdx);
            return (
              <tr
                key={rowIdx}
                style={{ cursor: onClickRow && "pointer" }}
                className={`odd:bg-gray-300 even:bg-gray-100 ${onClickRow ? "hover:bg-slate-200" : ""
                  }`}
              >
                {enableCheckboxSelection && (
                  <td className="p-1">
                    <div
                      onClick={() => handleRowSelection(rowIdx, isRowSelected)}
                      className="bg-white w-fit border border-gray-500 cursor-pointer"
                    >
                      <CheckIcon
                        className="w-4"
                        style={{
                          visibility: isRowSelected ? "visible" : "hidden",
                        }}
                      />
                    </div>
                  </td>
                )}
                {columns.map((column, colIdx) => {
                  const { width, maxWidth, minWidth } = column;
                  const value = (row as any)[column.accessKey];
                  return (
                    <td
                      onClick={() => onClickRow?.(rowIdx, colIdx, value, row)}
                      key={colIdx}
                      style={{ width, maxWidth, minWidth }}
                      className="px-3 py-1.5 truncate "
                    >
                      {column.format
                        ? column.format(value, row, rowIdx)
                        : value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot style={{ display: hideFooter ? "none" : undefined }}>
          <tr>
            <td colSpan={columns.length}>
              <Pagination
                count={totalRows || 10}
                defaultPage={pageNumber}
                onChange={_onPaginationChange}
                defaultPageSize={rowsPerPage}
                pageSizes={[10, 25, 20, 100]}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
