import { createAsyncThunk } from "@reduxjs/toolkit";
import * as mut from "../../../graphql/mutations";
import {
  GeneralResponse,
  InviteUserToTeamInput,
  QueryGetEventsArgs,
  QueryGetTeamsArgs,
  RegisterTeamInput,
  RegisterTeamOutput,
  Team,
  UpdateTeamInput,
} from "../../../graphql/schemaTypes";
import * as qur from "../../../graphql/queries";
import { Callback } from "../../../types";

export const registerTeam = createAsyncThunk(
  "mutation/registerTeam",
  async (
    {
      input,
    }: {
      input: RegisterTeamInput;
      callback?: Callback<string, RegisterTeamOutput>;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await mut.registerTeam({ input });
      console.log("registerTeamMutation", data);
      return data.registerTeam;
    } catch (err) {
      console.log("registerTeamMutationError", err);
      const error: any = err;
      throw error.errors?.[0]?.message;
    }
  }
);

export const getTeams = createAsyncThunk(
  "mutation/getTeams",
  async (input: QueryGetTeamsArgs, thunkAPI) => {
    try {
      const { data } = await qur.getTeams(input);
      console.log("getTeams", data);
      return data.getTeams;
    } catch (err) {
      console.log("getTeamsError", err);
      const error: any = err;
      throw error.errors?.[0]?.message;
    }
  }
);

export const getEvents = createAsyncThunk(
  "query/getEvents",
  async (input: QueryGetEventsArgs, thunkAPI) => {
    try {
      const { data } = await qur.getEvents(input);
      return data.getEvents;
    } catch (err) {
      console.log("getEventsError", err);
    }
  }
);

export const updateTeam = createAsyncThunk(
  "mutation/updateTeam",
  async (
    { input }: { input: UpdateTeamInput; callback?: Callback<string, Team> },
    thunkAPI
  ) => {
    console.log("updateTeam", input);
    try {
      const { data } = await mut.updateTeam({ input });
      console.log("updateTeamMutation", data);
      return data.updateTeam;
    } catch (err) {
      console.log("updateTeamMutationError", err);
      const error: any = err;
      throw error.errors?.[0]?.message;
    }
  }
);

export const inviteUserToTeam = createAsyncThunk(
  "mutation/inviteUserToTeam",
  async (
    {
      input,
    }: {
      input: InviteUserToTeamInput;
      callback?: Callback<string, GeneralResponse>;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await mut.inviteUserToTeam(input);
      console.log("inviteUserToTeamMutation", data);
      return data.inviteUserToTeam;
    } catch (err) {
      console.log("inviteUserToTeamError", err);
      const error: any = err;
      throw error.errors?.[0]?.message;
    }
  }
);
