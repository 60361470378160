import * as yup from "yup";
import React, { Fragment } from "react";
import { SelectField } from "@aws-amplify/ui-react";
import { MinusCircleIcon } from "@heroicons/react/20/solid";
import { Form, Formik, FormikHelpers, useField } from "formik";

import {
  GRAD_YEAR,
  HEIGHT,
  WEIGHT,
  checkPhoneAndEmail,
  getPositionList,
  hasDuplicateEmails,
  hasDuplicateJerseyNumbers,
  hasDuplicatePhoneNumbers,
} from "../../../util/registration";
import Button from "../../../components/button";
import Spinner from "../../../components/spinner";
import AutoCompleteField from "./AutoCompleteField";
import { getAddress } from "../../../util/onboarding";
import { useAppSelector } from "../../../redux/store";
import { EmailRegex, phoneRegex } from "../../../util/constants";
import { InputUser, UserRole, User } from "../../../graphql/schemaTypes";
import { FormErrorField, FormInputField } from "../../../components/form";

const formSchema = yup.object({
  name: yup.string().required("Required"),
  players: yup
    .array()
    .of(yup.mixed<InputUser>().required("Required"))
    .required("Required"),
  coaches: yup
    .array()
    .of(yup.mixed<InputUser>().required("Required"))
    .required("Required"),
});

export interface FormFields extends yup.InferType<typeof formSchema> {}

const _initialValues: FormFields = {
  name: "",
  coaches: [],
  players: [],
};

interface Props {
  initialValues?: FormFields;
  type: "Create" | "Update";
  onSubmit?: (values: FormFields) => Promise<void>;
}

const commonClass = `
[&>:nth-child(1)]:text-slate-600 [&>:nth-child(1)]:text-[13px]
[&>:nth-child(2)]:bg-white [&>:nth-child(2)]:p-[2px_4px] [&>:nth-child(2)]:text-[14px]
`;

const TeamForm: React.FC<Props> = ({ initialValues, type, onSubmit }) => {
  const handleSubmit = async (
    { coaches, name, players }: FormFields,
    { setSubmitting, setFieldError }: FormikHelpers<FormFields>
  ) => {
    await onSubmit?.({
      name,
      coaches: coaches.map((c) => ({
        id: c.id,
        firstName: c.firstName,
        lastName: c.lastName,
        email: c.email,
        phoneNumber: c.phoneNumber,
      })),
      players: players.map((c) => ({
        id: c.id,
        firstName: c.firstName,
        lastName: c.lastName,
        email: c.email,
        phoneNumber: c.phoneNumber,
        hsGradYear: c.hsGradYear,
        position: c.position,
        height: c.height,
        address: c.address,
        city: c.city,
        state: c.state,
        zipCode: c.zipCode,
        jerseyNumber: c.jerseyNumber,
        weight: c.weight,
      })),
    });
    setSubmitting(false);
  };

  const handleValidation = (values: FormFields) => {
    const error: { [P in keyof FormFields]?: string } = {};
    const mergedArray = [...values.coaches, ...values.players];
    if (values.players.length === 0) {
      error.players = "Please add an athlete";
    }
    if (!checkPhoneAndEmail(values.coaches)) {
      error.coaches = "Cell # and Email both are required";
    }
    if (!checkPhoneAndEmail(values.players)) {
      error.players = "Cell # and Email both are required";
    }
    if (hasDuplicateJerseyNumbers(values.players)) {
      error.players = "Nobody in the team should have the same Jersey #";
    }
    if (hasDuplicateEmails(mergedArray)) {
      error.coaches = "Nobody in the team should have the same Email";
    }
    if (hasDuplicatePhoneNumbers(mergedArray)) {
      error.coaches = "Nobody in the team should have the same Cell #";
    }
    values.players.forEach(({ email }) => {
      if (email && !email?.match(EmailRegex)) {
        error.players = "Please enter a valid email";
      }
    });
    values.coaches.forEach(({ email }) => {
      if (email && !email?.match(EmailRegex)) {
        error.coaches = "Please enter a valid email";
      }
    });
    values.coaches.forEach(({ phoneNumber }) => {
      if (phoneNumber && !phoneNumber?.match(phoneRegex)) {
        error.coaches = "Please enter a valid US phone number";
      }
    });
    values.players.forEach(({ phoneNumber }) => {
      if (phoneNumber && !phoneNumber?.match(phoneRegex)) {
        error.players = "Please enter a valid US phone number";
      }
    });
    return error;
  };

  return (
    <div className="bg-gray-200 w-full p-4">
      <Formik
        initialValues={initialValues || _initialValues}
        onSubmit={handleSubmit}
        validationSchema={formSchema}
        validate={handleValidation}
      >
        {({ values, setValues, errors, isSubmitting }) => {
          const handleAddNewCoach = () => {
            setValues((v) => {
              v.coaches.push({
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
              });
              return { ...v, coaches: v.coaches };
            });
          };
          const handleAddNewPlayer = () => {
            setValues((v) => {
              v.players.push({
                email: "",
                phoneNumber: "",
                firstName: "",
                lastName: "",
                jerseyNumber: "",
                hsGradYear: "",
                position: "",
                height: "",
                weight: "",
                address: "",
                city: "",
                state: "",
                zipCode: "",
              });
              return { ...v, players: v.players };
            });
          };
          const handleRemoveCoach = (rowIdx: number) => {
            setValues((v) => {
              const newCoaches = [...v.coaches];
              newCoaches.splice(rowIdx, 1);
              return {
                ...v,
                coaches: newCoaches.map((coach) => ({
                  ...coach,
                  selected: false,
                })),
              };
            });
          };
          const handleRemovePlayer = (rowIdx: number) => {
            setValues((v) => {
              const newPlayers = [...v.players];
              newPlayers.splice(rowIdx, 1);
              return {
                ...v,
                players: newPlayers.map((player) => ({
                  ...player,
                  selected: false,
                })),
              };
            });
          };

          return (
            <Form>
              <FormInputField
                className={commonClass + "flex flex-col w-48"}
                label="Team Name"
                name="name"
              />
              <br />
              {/* ////////////// Coaches info //////////// */}
              <div className="flex justify-between mb-2">
                <h2 className="text-xl font-semibold">Coach Information</h2>
                <Button
                  type="button"
                  onClick={handleAddNewCoach}
                  color="secondary"
                  size="small"
                >
                  Add Coach
                </Button>
              </div>
              <FormErrorField name="coaches" />
              <CoachesInputTable
                rows={values.coaches}
                handleRemoveRow={handleRemoveCoach}
              />
              <br /> <br />
              {/* ////////////// Coaches info //////////// */}
              <div className="flex justify-between mb-2">
                <h2 className="text-xl font-semibold">Athlete Information</h2>
                <Button
                  type="button"
                  onClick={handleAddNewPlayer}
                  color="secondary"
                  size="small"
                >
                  Add Athlete
                </Button>
              </div>
              <FormErrorField name="players" />
              <PlayersInputTable
                rows={values.players}
                handleRemoveRow={handleRemovePlayer}
              />
              <br />
              {/* ////////////// Submit  //////////// */}
              <div className="flex justify-center">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="secondary"
                  size="medium"
                  className="flex items-center"
                >
                  {type === "Create" ? "Submit" : "Update"}
                  {isSubmitting && <Spinner className="ml-2" h={15} w={15} />}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TeamForm;

function CoachesInputTable({
  rows,
  handleRemoveRow,
}: {
  rows: InputUser[];
  handleRemoveRow?: (rowIdx: number) => void;
}) {
  const fields: { label: string; name: keyof InputUser }[] = [
    { label: "First Name", name: "firstName" },
    { label: "Last Name", name: "lastName" },
    { label: "Email", name: "email" },
    { label: "Cell #", name: "phoneNumber" },
    { label: "Action", name: "id" },
  ];

  return (
    <div className="">
      <div className="grid grid-cols-5 gap-y-1 max-w-[1300px]">
        {fields.map(({ label, name }, idx) => {
          return (
            <div
              key={idx}
              className="p-1 col-span-1 font-semibold bg-gray-400 text-white text-center"
            >
              {label}
            </div>
          );
        })}
        {rows.map((row, rowIdx) => {
          return (
            <Fragment key={rowIdx}>
              {fields.map(({ name, label }, idx) => {
                return (
                  <div
                    key={idx}
                    className="mr-1 bg-white col-span-1 flex justify-center"
                  >
                    {label === "Action" ? (
                      <MinusCircleIcon
                        className="text-red-600 w-5 cursor-pointer"
                        onClick={() => handleRemoveRow?.(rowIdx)}
                      />
                    ) : // <InputField name={`coaches[${rowIdx}].${name}`} />
                    name === "email" ? (
                      <AutoCompleteField
                        fieldName={`coaches[${rowIdx}].${name}`}
                        searchByFeild={name}
                        userRole={UserRole.Recruiter}
                      />
                    ) : (
                      <InputField name={`coaches[${rowIdx}].${name}`} />
                    )}
                  </div>
                );
              })}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

function PlayersInputTable({
  rows,
  handleRemoveRow,
}: {
  rows: InputUser[];
    handleRemoveRow?: (rowIdx: number) => void;
    }) {
        const userData = useAppSelector((s) => s.auth.userData);
  const fields: { label: string; name: keyof InputUser, span: number }[] = [
    { label: "First Name", name: "firstName", span: 1, },
    { label: "Last Name", name: "lastName", span: 1, },
    { label: "Jersey #", name: "jerseyNumber", span: 1, },
    { label: "Grad Year", name: "hsGradYear", span: 1, },
    { label: "Weight", name: "weight", span: 1, },
    { label: "POS", name: "position", span: 1, },
    { label: "Height", name: "height", span: 1, },
    { label: "Address", name: "address", span: 2, },
    { label: "City", name: "city", span: 1,},
    { label: "State", name: "state", span: 1, },
    { label: "Zip Code", name: "zipCode", span: 1, },
    { label: "Cell #", name: "phoneNumber", span: 1, },
    { label: "Email", name: "email", span: 2, },
    { label: "Action", name: "id", span: 1, },
  ];

  const getList = (name:string) => {
    let options 
    switch (name) {
      case 'hsGradYear':
        options = GRAD_YEAR
        break;
        case 'position':
          options = getPositionList(userData?.recruitSport);
        break;
        case 'height':
          options = HEIGHT
        break;
        case 'weight':
          options = WEIGHT
          break;
      default:
        options = HEIGHT
        break;
    }
    return options
  }

  const handleOnSelectUser = (user?: User) => {
    if (!user) return;
  };
  const totalSpan = fields.map(v => v.span).reduce((a, b) => a + b, 0);
  return (
    <div className="overflow-x-auto">
      <div className={`grid gap-y-1 w-[2200px]`} style={{ gridTemplateColumns: `repeat(${totalSpan}, minmax(0, 1fr))` }}>
        {fields.map(({ label, name, span }, idx) => {
          return (
            <div
              key={idx}
              style={{ gridColumn: `span ${span} / span ${span}` }} className="p-1 font-semibold bg-gray-400 text-white text-center" 
            >
              {label}
            </div>
          );
        })}
        {rows.map((row, rowIdx) => {
          return (
            <Fragment key={rowIdx}>
              {fields.map(({ name, label, span }, idx) => {
                return (
                  <div
                    key={idx}
                    className="mr-1 bg-white flex justify-center h-[24px] last:mb-28" style={{ gridColumn: `span ${span} / span ${span}` }}
                  >
                    {label === "Action" ? (
                      <MinusCircleIcon
                        className="text-red-600 w-5 cursor-pointer"
                        onClick={() => handleRemoveRow?.(rowIdx)}
                      />
                    ) :
                    name === "email" ? (
                      <AutoCompleteField
                        fieldName={`players[${rowIdx}].${name}`}
                        searchByFeild={name}
                        userRole={UserRole.Athlete}
                        onSelect={handleOnSelectUser}
                      />
                      ) : (
                          name === 'position' || name === 'hsGradYear' || name==='height' || name==='weight' ? <SelectInput name={`players[${rowIdx}].${name}`} list={getList(name)}/> : 
                      <InputField name={`players[${rowIdx}].${name}`} />
                    )}
                  </div>
                );
              })}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

const InputField = ({ name = "" }) => {
  const [field, meta, helpers] = useField({ name });
  const [userObjectFieldName] = name.split(".");
  const [_field, _meta, _helpers] = useField({ name: userObjectFieldName });
  // Utility to check if the name includes specific fields
  const includesName = (field) => name?.includes(field);
  // Handle change event for the input
  const handleOnChange = async (e) => {
    if (includesName("zipCode")) {
      const newZipcode = e.target.value;
      if (newZipcode.length === 5) {
        try {
          const address = await getAddress(newZipcode);
          if (address && address.length === 2) {
            _helpers.setValue({
              ..._meta.value,
              state: address[0],
              city: address[1],
            });
          }
        } catch (error) {
          console.error("Error fetching address:", error);
          _helpers.setValue({ ..._meta.value, state: "", city: "" });
        }
      } else {
        _helpers.setValue({ ..._meta.value, state: "", city: "" });
      }
    }
    field.onChange(e);
  };
  // Determine the type of the input based on its name
  const inputType = includesName("jerseyNumber")
    ? 3
    : includesName("zipCode")
    ? 5
    : undefined;
  const disableField = () => {
    if (
      (includesName("phoneNumber") ||
        includesName("firstName") ||
        includesName("lastName")) &&
      (_meta.value?.selected || _meta.value?.isOnboarded)
    ) {
      return true;
    }
  };

  return (
    <input
      className="w-full px-1"
      type="text"
      onBlur={field.onBlur}
      defaultValue={meta.value}
      name={name}
      disabled={disableField() || includesName("state") || includesName("city")}
      onChange={handleOnChange}
      maxLength={inputType}
      style={{
        fontSize: 12,
      }}
    />
  );
};

const SelectInput = ({ name = "", list = HEIGHT }) => {
  const [field, meta, helpers] = useField({ name });
  const handleSelect = (event) => {
    helpers.setValue(event.target.value)
}
  return (
    <SelectField
      label={name}
      labelHidden
      style={{
        display: "flex",
        height: 24,
        border: "none",
        paddingTop: 0,
        fontSize: 12,
        width: 141,
      }}
      value={meta.value}
      size="small"
      placeholder="Select"
      onChange={handleSelect}
    >
      {list?.map((element) => (
        <option key={element?.value}>{element?.value}</option>
      ))}
    </SelectField>
  );
}
