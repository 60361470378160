import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  createUserMutation,
  loadAuthenticatedUser,
  signOut,
} from "./asyncThunks";
import { handlePending, handleReject } from "../../helper";
import { InputUser, User } from "../../../graphql/schemaTypes";

export type AuthState =
  | "SIGNED_IN"
  | "SIGNED_OUT"
  | "PENDING"
  | "ON_BOARD"
  | null;

interface StateType {
  authState: AuthState;
  userData: User | null;
  createUserData: InputUser;
  fetchingStatus: {
    createUserMutation: boolean;
  };
  error: {
    createUserMutation: null | string;
  };
}

const initialState: StateType = {
  authState: null,
  userData: null,
  fetchingStatus: {
    createUserMutation: false,
  },
  error: {
    createUserMutation: null,
  },
  createUserData: {
    role: "recruiter",
    class: null,
    title: null,
    recruitGender: null,
    recruitSport: null,
    organization: null,
    firstName: null,
    lastName: null,
  },
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateCreateUserData: (state, { payload }: PayloadAction<any>) => {
      state.createUserData = {
        ...state.createUserData,
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadAuthenticatedUser.fulfilled, (state, { payload }) => {
      if (payload) {
        state.userData = payload;
        state.authState = "SIGNED_IN";
      } else {
        state.userData = null;
        state.authState = "ON_BOARD";
      }
    });
    builder.addCase(loadAuthenticatedUser.pending, (state, { payload }) => {
      state.authState = "PENDING";
    });
    builder.addCase(loadAuthenticatedUser.rejected, (state, { payload }) => {
      state.authState = "SIGNED_OUT";
    });
    builder.addCase(signOut.fulfilled, (state, { payload }) => {
      state.authState = "SIGNED_OUT";
      state.userData = null;
    });
    builder.addCase(signOut.rejected, (state, { payload }) => {
      state.authState = "SIGNED_OUT";
      state.userData = null;
    });
    builder.addCase(createUserMutation.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }
      state.fetchingStatus.createUserMutation = false;
      state.error.createUserMutation = null;
      state.userData = payload;
      state.authState = "SIGNED_IN";
    });
    builder.addCase(
      createUserMutation.pending,
      handlePending("createUserMutation")
    );
    builder.addCase(
      createUserMutation.rejected,
      handleReject("createUserMutation")
    );
  },
});

export default slice.reducer;
export const { updateCreateUserData } = slice.actions;

export { loadAuthenticatedUser, signOut, createUserMutation };
