import { FC, useState } from "react";
import {
  Authenticator,
  Heading,
  useAuthenticator,
  useTheme,
  View,
  withAuthenticator,
  WithAuthenticatorProps,
} from "@aws-amplify/ui-react";
import { signIn } from "aws-amplify/auth";
import { I18n } from "aws-amplify/utils";

import "./Auth.css";
import AuthModal from "./AuthModal";
import PasswordText from "../components/auth/components/passwordText";

I18n.putVocabulariesForLanguage("en", {
  "Sign in": "Log in",
});

const Auth: FC<WithAuthenticatorProps> = ({ user }) => {
  return <></>;
};
export default withAuthenticator(Auth, {
  components: {
    SignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            <span style={{ fontWeight: "bold" }}>Log in</span>
          </Heading>
        );
      },
      Footer() {
        const { toForgotPassword, toSignUp } = useAuthenticator();
        return (
          <>
            <View textAlign="right" padding="xs">
              <p>
                &nbsp;
                <span
                  style={{
                    color: "#1889DB",
                    cursor: "pointer",
                  }}
                  onClick={toForgotPassword}
                >
                  Forgot Password?
                </span>
              </p>
            </View>
            <View textAlign="center" padding="xs">
              <p>
                Don't have an account?&nbsp;
                <span
                  style={{
                    color: "#1889DB",
                    cursor: "pointer",
                  }}
                  onClick={toSignUp}
                >
                  Sign up
                </span>
              </p>
            </View>
          </>
        );
      },
    },
    SignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            <span style={{ fontWeight: "bold" }}>Create an account</span>
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
        const [isTermsModalOpen, setTermsModalOpen] = useState(false);
        const [isPrivacyModalOpen, setPrivacyModalOpen] = useState(false);
        return (
          <>
            <View textAlign="center">
              <p>
                By tapping Create Account, you are agreeing to our&nbsp;
                <span
                  style={{
                    color: "#1889DB",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setTermsModalOpen(true)}
                >
                  Terms
                </span>
                &nbsp;and&nbsp;
                <span
                  style={{
                    color: "#1889DB",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setPrivacyModalOpen(true)}
                >
                  Privacy Policy
                </span>
              </p>
            </View>
            <br />
            <View textAlign="center" padding="xs">
              <p>
                Don't have an account?&nbsp;
                <span
                  style={{
                    color: "#1889DB",
                    cursor: "pointer",
                  }}
                  onClick={toSignIn}
                >
                  Log in
                </span>
              </p>
            </View>
            {isTermsModalOpen && (
              <AuthModal
                modalType="terms"
                handleClose={() => setTermsModalOpen(false)}
              />
            )}
            {isPrivacyModalOpen && (
              <AuthModal
                modalType="privacy"
                handleClose={() => setPrivacyModalOpen(false)}
              />
            )}
          </>
        );
      },
      FormFields() {
        return (
          <>
            <Authenticator.SignUp.FormFields />
            <PasswordText />
          </>
        );
      },
    },
    ConfirmResetPassword: {
      Footer() {
        return <PasswordText />;
      },
    },
  },
  formFields: {
    signIn: {
      username: {
        placeholder: "Email",
        label: "",
        type: "email",
      },
      password: {
        placeholder: "Password",
        label: "",
      },
    },
    signUp: {
      username: {
        placeholder: "Email",
        label: "",
        type: "email",
      },
      password: {
        placeholder: "Password",
        label: "",
        isRequired: true,
      },
      confirm_password: {
        placeholder: "Please confirm your password",
        label: "",
        isRequired: true,
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: "Enter Code",
        label: "",
        isRequired: true,
      },
      password: {
        placeholder: "New Password",
        label: "",
        isRequired: true,
      },
      confirm_password: {
        placeholder: "Please confirm your password",
        label: "",
        isRequired: true,
      },
    },
    forgotPassword: {
      username: {
        placeholder: "Email",
        label: "",
        type: "email",
      },
    },
  },
  services: {
    async handleSignIn({ username, options, password }) {
      const res = await signIn({
        username,
        password,
        options: {
          ...options,
          authFlowType: "USER_PASSWORD_AUTH",
        },
      });
      return res;
    },
  },
});
