import React, { useState, useEffect } from "react";
import { setDialogHandler } from "../../redux/features/alerts.slice";
import Dialog, { Severity, DialogStateType, DialogResponse } from "./dialog";
import Alert, { AlertStateType } from "./alert";
import HeaderAlert, { HeaderAlertStateType } from "./headerAlert";
import { useAppDispatch } from "../../redux/store";

export type ShowDialogCallback = (
  severity: Severity,
  title: string,
  message: string,
  onResponse?: DialogStateType["callback"]
) => Promise<DialogResponse>;

interface Props {
  children: React.ReactNode;
}
export const DialogRootWrapper = ({ children }: Props) => {
  const [dialogState, setDialogState] = useState<DialogStateType>({
    open: false,
    title: "Some thing went wrong",
    message: "",
    severity: "error",
    callback: () => {},
  });
  const [alertState, setAlertState] = useState<AlertStateType[]>([]);
  const [headerAlertState, setHeaderAlertState] = useState<
    HeaderAlertStateType[]
  >([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setDialogHandler({ showDialog, showAlert, showHeaderAlert }));
  }, []);

  const resetState = () => {
    setDialogState((e) => ({ ...e, open: false }));
  };

  const showDialog: ShowDialogCallback = async (
    severity,
    title,
    message,
    onResponse
  ) => {
    return await new Promise<DialogResponse>((resolve) => {
      setDialogState({
        open: true,
        severity,
        title,
        message,
        callback: (v) => {
          resetState();
          onResponse && onResponse(v);
          resolve(v);
        },
      });
    });
  };

  const showAlert = (alert: Omit<AlertStateType, "id">) => {
    console.log("alert displayed");
    setAlertState((e) => [
      ...e,
      { ...alert, id: `${Math.random() + Math.random()}` },
    ]);
  };
  const removeAlert = (id: string) => {
    setAlertState((e) => {
      return e.filter((_) => _.id !== id);
    });
  };

  const showHeaderAlert = (alert: Omit<AlertStateType, "id">) => {
    setHeaderAlertState((e) => [
      ...e,
      { ...alert, id: `${Math.random() + Math.random()}` },
    ]);
  };
  const removeHeaderAlert = (id: string) => {
    setHeaderAlertState((e) => {
      return e.filter((_) => _.id !== id);
    });
  };

  return (
    <>
      <div>
        <Dialog
          open={dialogState.open}
          severity={dialogState.severity}
          title={dialogState.title}
          message={dialogState.message}
          onResponse={dialogState.callback}
        />
      </div>

      <span className="fixed top-0 -right-8 p-4 z-[1000] space-y-2 overflow-x-hidden">
        {alertState.map(({ message, severity, id }, idx) => {
          return (
            <Alert
              key={idx}
              id={id}
              message={message}
              severity={severity}
              handleClose={removeAlert}
            />
          );
        })}
      </span>

      <>
        {headerAlertState.map(({ severity, id, message }, idx) => {
          return (
            <HeaderAlert
              key={idx}
              id={id}
              message={message}
              severity={severity}
              handleClose={removeHeaderAlert}
            />
          );
        })}
      </>
      {children}
    </>
  );
};
