import { Navigate } from "react-router-dom";

import { PAGES } from "./app-config";
import { useAppSelector } from "./redux/store";

const ProtectedRoute = ({ children }) => {
  const authState = useAppSelector((s) => s.auth.authState);
  const isAuthenticated = authState === "SIGNED_IN";

  if (!isAuthenticated) {
    // Redirect to the login page if not authenticated
    return <Navigate to={`/${PAGES.auth.path}`} replace />;
  }
  return children;
};

export default ProtectedRoute;
