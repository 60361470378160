import { Loader } from "@aws-amplify/ui-react";
import { FC, useCallback, useEffect, useRef, useState } from "react";

import {
  DIVISION_LIST,
  DROPDOWN_FIELDS,
  GENDER_LIST,
  MEN_SPORTS,
  WOMEN_SPORTS,
} from "../../../../util/constants";
import RolesDropdown from "./rolesDropdown";
import Modal from "../../../../components/modal";
import { useAppDispatch } from "../../../../redux/store";
import OrganisationLocation from "./organisationLocation";
import Chevron from "../../../../assets/icons/chevron.svg";
import { Organization } from "../../../../graphql/schemaTypes";
import { searchOrganizations } from "../../../../graphql/queries";
import organisation from "../../../../assets/icons/organisation.svg";
import { updateCreateUserData } from "../../../../redux/features/auth.slice";

type SelectedItems = {
  [key in (typeof DROPDOWN_FIELDS)[number]]: string | null;
};

const AboutTeam: FC = () => {
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredOrgs, setFilteredOrgs] = useState<any>([]);
  const [openModalFor, setOpenModalFor] = useState("Division");
  const [selectedItems, setSelectedItems] = useState<SelectedItems>(
    DROPDOWN_FIELDS.reduce(
      (acc, field) => ({
        ...acc,
        [field]: null,
      }),
      {} as SelectedItems
    )
  );
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const openModal = (text: string) => {
    setOpenModalFor(text);
    setIsModalOpen(true);
  };

  const handleSelect = (value: string) => {
    if (openModalFor === "Gender") {
      setSelectedItems((prevState) => ({
        ...prevState,
        Sport: null,
      }));
    }
    setSelectedItems((prevState) => ({
      ...prevState,
      [openModalFor]: value,
    }));
    setIsModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(
      updateCreateUserData({
        class: selectedItems.Division,
        recruitSport: selectedItems.Sport,
        recruitGender:
          selectedItems.Gender === GENDER_LIST[0] ? `Men's` : `Women's`,
      })
    );
  }, [selectedItems]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputValue(event.target.value);
    if (event.target.value) {
      setIsLoading(true);
      setShowDropdown(true);
      try {
        const { data } = await searchOrganizations(event.target.value);
        if (data?.searchOrganizations?.length) {
          const filtered = data.searchOrganizations.filter((org: any) =>
            org.name.toLowerCase().includes(event.target.value.toLowerCase())
          );
          setFilteredOrgs(filtered);
        }
      } catch (error) {}
      setIsLoading(false);
      } else {
        dispatch(
          updateCreateUserData({
            organization: { name: "", zipCode: "", state: "", city: "" },
          })
        );
        setShowDropdown(false);
      }
  };

  const handleSelectOrg = (org: Organization) => {
    setInputValue(org.name);
    setShowDropdown(false);
    const data = {
      city: org.city,
      name: org.name,
      state: org.state,
      zipCode: org.zipCode,
    };
    dispatch(updateCreateUserData({ organization: data }));
  };

  const handleModalData = () => {
    let data: string[] = [];
    switch (openModalFor) {
      case "Division":
        data = DIVISION_LIST;
        break;
      case "Gender":
        data = GENDER_LIST;
        break;
      case "Sport":
        data =
          selectedItems.Gender === GENDER_LIST[0] ? MEN_SPORTS : WOMEN_SPORTS;
        break;
      default:
        data = DIVISION_LIST;
        break;
    }
    return data;
  };

  const programValue = useCallback(() => {
    const { Gender, Sport } = selectedItems;
    return Gender && Sport
      ? `${Gender === GENDER_LIST[0] ? "Men's" : "Women's"} ${Sport}`
      : "";
  }, [selectedItems]);

  const disableRegister = useCallback(() => {
    const { Division } = selectedItems;
    if (
      Division && (Division !== DIVISION_LIST[0] && Division !== DIVISION_LIST[1])
    ) {
      return true;
    }
    return false
  }, [selectedItems]);

  return (
    <>
      <div className="w-full max-w-lg mt-10">
        <img src={organisation} alt="organisation" />
        <h2 className="text-left mb-6 mt-5 text-twentyEight font-bold">
          Tell us about your team
        </h2>
        <div className=" rounded-xl w-full max-w-lg bg-surface-fill">
          <form>
            <div className="relative border-b-2 " ref={wrapperRef}>
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                className=" p-4 rounded-xl w-full appearance-none bg-transparent"
                placeholder="Select organisation"
              />
              {isLoading && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  <Loader ariaLabel="Loading..." />
                </div>
              )}
              {showDropdown && (
                <ul className="absolute z-10 w-full border bg-white mt-1 rounded max-h-52 overflow-y-auto">
                  {filteredOrgs.map((org: Organization) => (
                    <li
                      key={org.id}
                      className="p-2 hover:bg-gray-100"
                      onClick={() => handleSelectOrg(org)}
                    >
                      {org.name}
                      <br />
                      <p className="text-secondary">
                        {org?.city && org?.state
                          ? org?.city + ", " + org.state
                          : "No location added"}
                      </p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {DROPDOWN_FIELDS.map((item) => (
              <div className="flex flex-row border-b-2" key={item}>
                <button
                  type="button"
                  className="text-base w-full text-gray-700  rounded-xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 flex justify-between items-center bg-surface-fill"
                  id={`options-menu-${item}`}
                  aria-haspopup="true"
                  aria-expanded="true"
                  onClick={() => openModal(item)}
                  disabled={item === "Sport" && !selectedItems.Gender}
                >
                  {selectedItems[item] || item}
                  <img
                    src={Chevron}
                    alt="chevron"
                    className="place-self-right"
                  />
                </button>
              </div>
            ))}
            <div className="flex flex-row">
              <p className="w-full p-4">Program</p>
              <input
                className=" appearance-none rounded-xl w-full p-4 text-gray-500 leading-tight text-right bg-transparent"
                id="program"
                type="text"
                placeholder="Program"
                disabled
                value={programValue()}
              />
            </div>
          </form>
        </div>
          {disableRegister() ? <p className="text-xs text-red-600">Only coaches with divisions "Club" and "Highschool" can register for a team</p>: null}
      </div>
      <OrganisationLocation />
      <RolesDropdown />
      <Modal
        data={handleModalData()}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        handleSelect={handleSelect}
      />
    </>
  );
};

export default AboutTeam;
