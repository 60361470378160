import { FC, ButtonHTMLAttributes } from "react";

const COLOR = {
  default: ["bg-gray-400", "hover:bg-gray-300", "hover:text-black"],
  primary: ["bg-lightgreen", "hover:bg-darkgreen", "hover:text-white"],
  secondary: ["bg-[#1889DB] text-white", "hover:bg-[#1073ba]", ""],
  ternary: ["bg-[#FFFFFF] text-[#1889DB] border-solid border-2 border-sky-500", "", ""],
  disabled: ["bg-gray-300", "", ""],
};

const SIZE = {
  small: "px-4 py-1 text-[0.8rem]",
  medium: "px-5 py-2 text-[0.95rem]",
  large: "px-7 py-2 text-[1.1rem]",
};

interface Props {
  color?: keyof typeof COLOR;
  size?: keyof typeof SIZE;
}

const Button: FC<Props & ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  color = "default",
  size = "medium",
  disabled,
  className,
  ...others
}) => {
  disabled && (color = "disabled");

  return (
    <button
      disabled={disabled}
      className={`${SIZE[size]} font-medium tracking-wide
             capitalize transition-colors border-0 
              duration-200 transform ${COLOR[color][0]} rounded-full ${COLOR[color][1]} 
              ${COLOR[color][2]}
              ${disabled ? "cursor-not-allowed" : "shadow-lg"}
              active:shadow-none
              focus:outline-none
              ${className}
              `}
      {...others}
    >
      {children}
    </button>
  );
};

export default Button;
