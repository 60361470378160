import { useEffect } from "react";
import { Amplify } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import Routes from "./routes";
import config from "./aws-exports";
import { PAGES } from "./app-config";
import { Hub } from "aws-amplify/utils";
import Loader from "./components/loader";
import { useAppDispatch, useAppSelector } from "./redux/store";
import { loadAuthenticatedUser, signOut } from "./redux/features/auth.slice";

Amplify.configure(config);
let appRenderOnce = false;

function App() {
  const authState = useAppSelector((s) => s.auth.authState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  /* Checking if the user is authenticated or not */
  useEffect(() => {
    if (appRenderOnce === false && authState === null) {
      appRenderOnce = true;
      console.log("==== in auth use Effect ====");
      dispatch(loadAuthenticatedUser({ forceRefresh: true }));
      Hub.listen("auth", ({ payload }) => {
        if (payload.event === "signedIn") {
          dispatch(loadAuthenticatedUser({ forceRefresh: true }));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (authState === "SIGNED_IN") {
      navigate(PAGES.home.path, { replace: true });
    } else if (authState === "SIGNED_OUT") {
      navigate(`/${PAGES.auth.path}`, { replace: true });
    } else if (authState === "ON_BOARD") {
      navigate(`/${PAGES.onboard.path}`, { replace: true });
    }
  }, [authState]);

  const handleSignOut = () => {
    dispatch(signOut());
  };

  return (
    <div className="App">
      <Loader open={!authState || authState === "PENDING"} />
      {authState !== "SIGNED_OUT" && (
        <button className="border-2 bg-slate-200" onClick={handleSignOut}>
          Sign out
        </button>
      )}
      <Routes />
    </div>
  );
}

export default App;
