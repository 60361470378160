
import { ResourcesConfig } from "aws-amplify";

// Prod
const awsmobile: ResourcesConfig = {
  API: {
    GraphQL: {
      endpoint: 'https://xc7r6r5navh3delgamp24kex2q.appsync-api.us-east-2.amazonaws.com/graphql',
      region: 'us-east-2',
      defaultAuthMode: 'userPool',
    },
  },
  Auth: {
    Cognito: {
      userPoolId: "us-east-2_uvZOiF3of",
      userPoolClientId: "uaaa3sv4ms230qm4qccdd3fdm",
      identityPoolId: "us-east-2:f3be9efb-5b73-4145-ae39-30adb5ae26df",
    }
  },
};

// dev/QA
// const awsmobile: ResourcesConfig = {
//   API: {
//     GraphQL: {
//       endpoint: 'https://kz46r23rjrfg5hbgqfuknbod7q.appsync-api.us-east-2.amazonaws.com/graphql',
//       region: 'us-east-2',
//       defaultAuthMode: 'userPool',
//     },
//   },
//   Auth: {
//     Cognito: {
//       userPoolId: "us-east-2_9x8kXYZMa",
//       userPoolClientId: "7mmgb0elqfc059tdbljqrvneoi",
//       identityPoolId: "us-east-2:0d455b5f-2a6e-4095-94db-79db914e1c87",
//       // userPoolId: "us-east-2_jY9rvhndy",
//       // userPoolClientId: "nokmh7mn9dvl2mui9qnr4btp1",
//       // identityPoolId: "us-east-2:af579677-7428-4f2f-b1de-f2d26f222d94",
//     }
//   },
// };


export default awsmobile;
