import { FC } from "react";

const COLOR = {
  default: ["border-gray-200"],
  primary: ["border-lightgreen"],
  secondary: ["border-blue-600"],
};

interface Props {
  color?: keyof typeof COLOR;
  h?: number;
  w?: number;
  className?: string;
}

const Spinner: FC<Props> = ({
  color = "default",
  h = 40,
  w = 40,
  className = "",
}) => {
  return (
    <div
      style={{
        borderTopColor: "transparent",
        display: "inline-block",
        width: w,
        height: h,
      }}
      className={`
                border-4 border-solid rounded-full animate-spin
                ${COLOR[color]}
                ${className}
            `}
    />
  );
};

export default Spinner;
