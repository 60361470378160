import {
  Query,
  QueryGetEventsArgs,
  QueryGetTeamsArgs,
  QuerySearchOrganizationsArgs,
  QuerySearchUsersArgs,
} from "./schemaTypes";
import { gqlQueryHandler } from "./utils";

export const getUser = async (userId: string) => {
  return await gqlQueryHandler<{ id: string }, { getUser: Query["getUser"] }>({
    query: /* GraphQL */ `
      query GetUser($id: ID!) {
        getUser(id: $id) {
          newNotification
          id
          email
          engagementPoints
          isDeleted
          firstName
          lastName
          role
          state
          city
          zipCode
          profilePicture
          jerseyNumber
          gender
          dateOfBirth
          height
          weight
          hsGradYear
          raceEthnicity
          sports {
            sport
            class
            position
          }
          nickname
          class
          organization {
            name
            zipCode
            city
            state
          }
          program {
            id
            sport
            seats
            division
            recruitGender
            admin {
              firstName
              lastName
              id
              email
              title
              profilePicture
            }
            members {
              user {
                firstName
                lastName
                id
                email
                title
                profilePicture
              }
              inviteAccepted
            }
          }
          subscription {
            clientSecret
            creationDate
            expirationDate
            plan
            price
            status
            subscriptionId
          }
          class
          recruitGender
          recruitSport
          title
          tenure
          bio
          gpa
          about
          pushTokens
          followed
          followerCount
          followingCount
          viewCount
          currentSchool
          clubOrganization
          favoriteFood
          favoriteSportToWatch
          favoriteHobby
          favoriteVacation
          gameFilm
          gameSchedule
          accolades
          funFact
          stats
          clubCoachInfo {
            email
            organization
            name
            phone
          }
          guardians {
            name
            relation
            phone
            email
          }
          highSchoolCoachInfo {
            email
            name
            organization
            phone
          }
          trainerInfo {
            email
            name
            organization
            phone
          }
          transcript {
            name
            uri
          }
          favoriteSport
          isOnboarded
        }
      }
    `,
    variables: { id: userId },
  });
};

export const searchOrganizations = async (name: string) => {
  return await gqlQueryHandler<
    QuerySearchOrganizationsArgs,
    { searchOrganizations: Query["searchOrganizations"] }
  >({
    query: /* GraphQL */ `
      query SearchOrganizations($input: SearchOrganizationsInput!) {
        searchOrganizations(input: $input) {
          city
          id
          name
          state
          zipCode
          conference
        }
      }
    `,
    variables: { input: { name } },
  });
};

export const searchUsers = async ({ input }: QuerySearchUsersArgs) => {
  return await gqlQueryHandler<
    QuerySearchUsersArgs,
    { searchUsers: Query["searchUsers"] }
  >({
    query: /* GraphQL */ `
      query SearchUsers($input: SearchUserInput!) {
        searchUsers(input: $input) {
          id
          email
          phoneNumber
          firstName
          lastName
          role
          state
          city
          zipCode
          profilePicture
          gender
          dateOfBirth
          height
          weight
          hsGradYear
          raceEthnicity
          sports {
            sport
            class
            position
          }
          nickname
          class
          organization {
            name
            zipCode
            city
            state
          }
          program {
            id
            sport
            division
            recruitGender
            seats
            admin {
              firstName
              lastName
              id
              email
              title
              profilePicture
            }
            members {
              user {
                firstName
                lastName
                id
                email
                title
                profilePicture
              }
              inviteAccepted
            }
          }
          subscription {
            clientSecret
            creationDate
            expirationDate
            plan
            price
            status
            subscriptionId
          }
          class
          recruitGender
          recruitSport
          title
          tenure
          bio
          gpa
          about
          pushTokens
          followed
          followerCount
          followingCount
          viewCount
          favoriteSport
          isOnboarded
        }
      }
    `,
    variables: { input },
  });
};

export const getTeams = async ({ input }: QueryGetTeamsArgs) => {
  return await gqlQueryHandler<
    QueryGetTeamsArgs,
    { getTeams: Query["getTeams"] }
  >({
    query: /* GraphQL */ `
      query MyQuery($input: GetTeamsInput!) {
        getTeams(input: $input) {
          items {
            id name registeredAt score
            registeredBy {
              id role email phoneNumber firstName lastName profilePicture isOnboarded
            } 
            coaches { 
              info { id role email phoneNumber firstName lastName profilePicture isOnboarded }
              inviteSent checkedIn
            }
            players { 
              info { 
                id role email phoneNumber firstName lastName profilePicture isOnboarded
                jerseyNumber hsGradYear position height address city state zipCode weight
              }
              inviteSent checkedIn
            }
          }
          total
        }
      }
    `,
    variables: { input },
  });
};

export const getEvents = async ({ input }: QueryGetEventsArgs) => {
  return await gqlQueryHandler<
    QueryGetEventsArgs,
    { getEvents: Query["getEvents"] }
  >({
    query: /* GraphQL */ `
      query GetEvents($input: GetEventsInput) {
        getEvents(input: $input) {
          count
          items {
            contact
            createdAt
            description
            division
            checkedIn
            gender
            id
            webAddress
            sport
            schedule
            name
            location {
              address
              city
              id
              state
              zipCode
            }
            description
            gender
            sport
            webAddress
            contact
            matches {
              court
              id
              startTime
              endTime
              teams {
                id
                name
                players {
                  checkedIn
                  info {
                    firstName
                    lastName
                    followed
                    profilePicture
                    id
                    role
                    sports {
                      sport
                      class
                      position
                    }
                    recruitGender
                    recruitSport
                  }
                }
              }
              videos {
                id
                key
                thumbnailKey
                caption
                filetype
                sport
                name
                uploadedDate
                owner {
                  __typename
                  ... on Event {
                    id
                    name
                    schedule
                    createdAt
                    description
                    location {
                      id
                      address
                      city
                      state
                      zipCode
                    }
                    gender
                    sport
                    division
                    contact
                    webAddress
                  }
                  ... on User {
                    id
                    profilePicture
                    firstName
                    lastName
                    role
                    sports {
                      sport
                      class
                      position
                    }
                    class
                    organization {
                      id
                      name
                      state
                      city
                      zipCode
                      conference
                    }
                    recruitSport
                    recruitGender
                  }
                }
                liked
                likeCount
                commentCount
                viewCount
              }
            }
          }
        }
      }
    `,
    variables: { input },
  });
};