import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import Table from '../../components/table';
import moment from 'moment';
import { getTeams, registerTeam, updateTeam } from '../../redux/features/team.slice';
import { Outlet, useNavigate } from "react-router-dom";
import { PAGES } from '../../app-config';
import Button from '../../components/button';
export { default as TeamsRoot } from './root';
export { default as Teams_TeamDetail } from './teamDetail';

const Teams = () => {

    return (
        <Outlet />
    )
}

export default Teams