import { FC } from "react";
import { Severity, color } from "./dialog";
import {
  XCircleIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";

export interface HeaderAlertStateType {
  severity: Severity;
  message: string;
  id: string;
}

const icon = {
  error: <XCircleIcon color="white" width={35} height={35} />,
  success: <CheckCircleIcon color="white" width={35} height={35} />,
  warning: <ExclamationCircleIcon color="white" width={35} height={35} />,
  confirmation: <QuestionMarkCircleIcon color="white" width={35} height={35} />,
  info: <InformationCircleIcon color="white" width={35} height={35} />,
};

const HeaderAlert: FC<
  HeaderAlertStateType & { handleClose: (index: any) => void }
> = ({ message, severity, id, handleClose }) => {
  return (
    <div className={`w-full ${color[severity]} flex p-2.5`}>
      <div className={`flex items-center justify-center w-12 `}>
        {icon[severity]}
      </div>
      <div className="w-full py-2">{message}</div>
      <div className="flex items-center justify-center w-12">
        <span
          onClick={() => {
            handleClose(id);
          }}
          className="px-2 py-1 pt-1.5 rounded-md hover:bg-opacity-25 hover:bg-gray-600 cursor-pointer"
        >
          <XMarkIcon className="" width={20} height={20} />
        </span>
      </div>
    </div>
  );
};
export default HeaderAlert;
