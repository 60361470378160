import { Amplify, } from 'aws-amplify';
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { signIn } from 'aws-amplify/auth';
import * as API from 'aws-amplify/api';
import * as Auth from 'aws-amplify/auth';
import { } from 'aws-amplify/utils';
import { CustomHeaders } from '@aws-amplify/data-schema-types';

interface GqlQueryHandlerProps<T> { query: string, variables?: T, headers?: CustomHeaders }
export const gqlQueryHandler = async <Input, Output = {}>({ query, variables, headers }: GqlQueryHandlerProps<Input>): Promise<API.GraphQLResult<Output>> =>
    await API.generateClient({
        // authMode: "userPool",
        authToken: (await Auth.fetchAuthSession()).tokens?.idToken?.toString()!
    }).graphql<Input | undefined>({ query, variables }) as any

