import { FC, useState } from "react";

import Modal from "../../../../../components/modal";
import { useAppDispatch } from "../../../../../redux/store";
import Chevron from "../../../../../assets/icons/chevron.svg";
import { COACH_ROLES_LIST } from "../../../../../util/constants";
import { updateCreateUserData } from "../../../../../redux/features/auth.slice";

const RolesDropdown: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const dispatch = useAppDispatch();

  const openModal = () => setIsModalOpen(true);
  const selectRole = (role: string) => {
    setSelectedRole(role);
    closeModal();
    dispatch(
      updateCreateUserData({
        title: role,
      })
    );
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="relative inline-block text-left w-full max-w-lg mt-8">
        <p className="text-left mb-6 ml-5 text-xs text-secondary">
          ABOUT YOU AT THIS ORGANIZATION
        </p>
        <button
          type="button"
          className="w-full bg-surface-fill text-fifteen text-gray-700  rounded-xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 flex justify-between items-center"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={openModal}
        >
          {selectedRole || "Role"}
          <img src={Chevron} alt="chevron" className="place-self-right" />
        </button>
      </div>
      <Modal
        data={COACH_ROLES_LIST}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        handleSelect={selectRole}
      />
    </>
  );
};

export default RolesDropdown;
