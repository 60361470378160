import { FC, useRef, useEffect } from "react";
import { delay } from "../../util/alerts";
import { Severity, icon, color } from "./dialog";

const alertTitle = {
  error: (
    <span className="font-semibold text-red-500 dark:text-red-400">Error</span>
  ),
  success: (
    <span className="font-semibold text-emerald-500 dark:text-emerald-400">
      Success
    </span>
  ),
  warning: (
    <span className="font-semibold text-yellow-400 dark:text-yellow-300">
      Warning
    </span>
  ),
  info: (
    <span className="font-semibold text-blue-500 dark:text-blue-400">Info</span>
  ),
  confirmation: (
    <span className="font-semibold text-blue-500 dark:text-blue-400">
      confirmation
    </span>
  ),
};

export interface AlertStateType {
  severity: Severity;
  message: string;
  id: string;
}

const Alert: FC<AlertStateType & { handleClose: (index: any) => void }> = ({
  message,
  severity,
  id,
  handleClose,
}) => {
  // https://merakiui.com/components/
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.transform = "translate(-10px)";
    }
  }, [ref.current]);

  useEffect(() => {
    (async () => {
      await delay(10000);
      _handleClose();
    })();
  }, []);

  const _handleClose = async () => {
    if (ref.current) {
      ref.current.style.transform = "translate(320px)";
    }
    await delay(400);
    handleClose(id);
  };

  return (
    <div
      ref={ref}
      onClick={_handleClose}
      style={{ transform: "translate(300px)", transition: " 0.4s ease-out" }}
      className="cursor-pointer flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md hover:shadow-lg dark:bg-gray-800"
    >
      <div
        className={`flex items-center justify-center w-12 ${color[severity]}`}
      >
        {icon[severity]}
      </div>

      <div className="px-4 py-2 -mx-3">
        <div className="mx-3">
          {alertTitle[severity]}
          <p className="text-sm text-gray-600 dark:text-gray-200">{message}</p>
        </div>
      </div>
    </div>
  );
};
export default Alert;
