import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSEmail: string;
  AWSTimestamp: number;
}

export enum Action {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export interface AddUserToWatchlistGroupInput {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
}

export interface CancelUserInviteInProgramInput {
  programId: Scalars['ID'];
  userId: Scalars['ID'];
}

export interface CheckInToEventOutput {
  __typename?: 'CheckInToEventOutput';
  eventAttendee: User;
  eventId: Scalars['ID'];
}

export interface CheckedInToEventInput {
  eventCode: Scalars['String'];
  eventId?: InputMaybe<Scalars['String']>;
}

export interface ClaimRewardInput {
  claimFor: RewardClaimFor;
  subjectNodeId: Scalars['ID'];
}

export interface CoachInfo {
  __typename?: 'CoachInfo';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
}

export interface CoachInfoInput {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
}

export interface Comment {
  __typename?: 'Comment';
  comment: Scalars['String'];
  createdAt: Scalars['AWSTimestamp'];
  videoId: Scalars['ID'];
}

export enum ComparisonOperators {
  Contains = 'CONTAINS',
  EndsWith = 'ENDS_WITH',
  Eq = 'EQ',
  Gt = 'GT',
  Gte = 'GTE',
  Lt = 'LT',
  Lte = 'LTE',
  Neq = 'NEQ',
  StartsWith = 'STARTS_WITH'
}

export interface Content {
  __typename?: 'Content';
  caption?: Maybe<Scalars['String']>;
  commentCount?: Maybe<Scalars['Int']>;
  filetype?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  likeCount?: Maybe<Scalars['Int']>;
  liked?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<ContentOwner>;
  shareCount?: Maybe<Scalars['Int']>;
  sport?: Maybe<Scalars['String']>;
  thumbnailKey?: Maybe<Scalars['String']>;
  uploadedDate?: Maybe<Scalars['AWSTimestamp']>;
  viewCount?: Maybe<Scalars['Int']>;
}

export interface ContentList {
  __typename?: 'ContentList';
  items: Array<Maybe<Content>>;
  total: Scalars['Int'];
}

export type ContentOwner = Event | User;

export interface Conversation {
  __typename?: 'Conversation';
  lastMessage?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pk: Scalars['String'];
  profileUrl?: Maybe<Scalars['String']>;
  recipient?: Maybe<User>;
  role?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  timestamp?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
}

export interface CreateConversationInput {
  profileUrl?: InputMaybe<Scalars['String']>;
  role: Scalars['String'];
  userId: Scalars['String'];
  userName?: InputMaybe<Scalars['String']>;
}

export interface CreateDirectMessageInput {
  conversationId: Scalars['String'];
  message: Scalars['String'];
  receiverName: Scalars['String'];
  receiverRole: Scalars['String'];
  receiverUrl?: InputMaybe<Scalars['String']>;
  rid: Scalars['String'];
  senderName: Scalars['String'];
  senderRole: Scalars['String'];
  senderUrl?: InputMaybe<Scalars['String']>;
  sid: Scalars['String'];
}

export interface CreateWatchlistGroupInput {
  name: Scalars['String'];
}

export interface DeleteUserByAdminInput {
  adminSecret: Scalars['String'];
  userId: Scalars['String'];
}

export interface DeleteUserInput {
  userId: Scalars['ID'];
}

export interface DeleteWatchlistGroupInput {
  id: Scalars['ID'];
}

export interface DirectMessage {
  __typename?: 'DirectMessage';
  message: Scalars['String'];
  pk: Scalars['String'];
  receiverUrl?: Maybe<Scalars['String']>;
  rid: Scalars['String'];
  role?: Maybe<Scalars['String']>;
  senderUrl?: Maybe<Scalars['String']>;
  sid: Scalars['String'];
  sk: Scalars['String'];
}

export interface Discount {
  __typename?: 'Discount';
  amount: Scalars['Float'];
  amountOff?: Maybe<Scalars['Int']>;
  percentOff?: Maybe<Scalars['Int']>;
}

export enum DivisionEnum {
  Club = 'Club',
  DivisionI = 'Division_I',
  DivisionIi = 'Division_II',
  DivisionIii = 'Division_III',
  Highschool = 'Highschool',
  Naia = 'NAIA'
}

export interface DocumentRequest {
  __typename?: 'DocumentRequest';
  createdAt: Scalars['AWSTimestamp'];
  id: Scalars['ID'];
  recruiter: User;
}

export interface Event {
  __typename?: 'Event';
  attendees?: Maybe<Array<Maybe<User>>>;
  checkedIn?: Maybe<Scalars['Boolean']>;
  contact: Scalars['String'];
  /**   depriciate.schedule */
  createdAt: Scalars['AWSTimestamp'];
  description: Scalars['String'];
  division: Scalars['String'];
  endTime?: Maybe<Scalars['AWSTimestamp']>;
  gender: GenderEnum;
  id: Scalars['ID'];
  location: Location;
  /**   this field is athelete and recruiter specific */
  matches?: Maybe<Array<Maybe<Match>>>;
  name: Scalars['String'];
  schedule?: Maybe<Scalars['AWSTimestamp']>;
  sport: Scalars['String'];
  startTime?: Maybe<Scalars['AWSTimestamp']>;
  webAddress: Scalars['String'];
}

export interface EventsList {
  __typename?: 'EventsList';
  count: Scalars['Int'];
  items: Array<Maybe<Event>>;
}

export interface FilterField {
  field: Scalars['String'];
  operator: ComparisonOperators;
  value: Scalars['String'];
  valueType?: InputMaybe<ValueType>;
}

export interface FilterOptions {
  filterBy?: InputMaybe<Array<FilterField>>;
  label?: InputMaybe<Array<Scalars['String']>>;
  logicalOperator?: InputMaybe<LogicalOperator>;
  page?: InputMaybe<Pagination>;
  sortBy?: InputMaybe<Array<SortBy>>;
}

export interface FollowedUser {
  __typename?: 'FollowedUser';
  followed: Scalars['Boolean'];
  followedAt?: Maybe<Scalars['AWSTimestamp']>;
  id: Scalars['ID'];
}

export enum GenderEnum {
  Female = 'FEMALE',
  Male = 'MALE'
}

export interface GeneralResponse {
  __typename?: 'GeneralResponse';
  data?: Maybe<Scalars['String']>;
  message: Scalars['String'];
}

export interface GetEventsInput {
  eventId?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Pagination>;
}

export interface GetSubscriptionPriceInput {
  promoCode?: InputMaybe<Scalars['String']>;
}

export interface GetTeamsInput {
  page?: InputMaybe<Pagination>;
  teamId?: InputMaybe<Scalars['ID']>;
}

export interface GetVideoViewersInput {
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  token?: InputMaybe<Scalars['String']>;
  videoId?: InputMaybe<Scalars['String']>;
}

export interface GetWatchlistGroupsInput {
  groupId?: InputMaybe<Scalars['ID']>;
}

export interface HandleInvitationToProgramInput {
  /**   programId: ID */
  invitationId: Scalars['ID'];
  isAccepted: Scalars['Boolean'];
}

export interface HandleInvitationToTeamInput {
  invitationId: Scalars['ID'];
}

export interface HandleOrganizationOfferInput {
  action: Action;
  orgId: Scalars['ID'];
}

export interface Id {
  __typename?: 'Id';
  id?: Maybe<Scalars['ID']>;
}

export interface InputUser {
  about?: InputMaybe<Scalars['String']>;
  /**   (Adding a link to their stats) */
  accolades?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<Scalars['String']>;
  clubCoachInfo?: InputMaybe<CoachInfoInput>;
  clubOrganization?: InputMaybe<Scalars['String']>;
  currentSchool?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  /**   (Adding link to Game Film)  */
  favoriteFood?: InputMaybe<Scalars['String']>;
  favoriteHobby?: InputMaybe<Scalars['String']>;
  /**   fan fields */
  favoriteSport?: InputMaybe<Scalars['String']>;
  favoriteSportToWatch?: InputMaybe<Scalars['String']>;
  favoriteVacation?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  funFact?: InputMaybe<Scalars['String']>;
  /**   (Adding link to Game Film)  */
  gameFilm?: InputMaybe<Scalars['String']>;
  /**   (adding organization name) */
  gameSchedule?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  gpa?: InputMaybe<Scalars['String']>;
  guardians?: InputMaybe<Array<InputMaybe<RelativePersonInfoInput>>>;
  /**   athlete fields */
  height?: InputMaybe<Scalars['String']>;
  /**   (S3 file path) */
  highSchoolCoachInfo?: InputMaybe<CoachInfoInput>;
  hsGradYear?: InputMaybe<Scalars['String']>;
  /**   common fields for fan, athlete and recruiter */
  id?: InputMaybe<Scalars['ID']>;
  /**   (Adding a link to news articles or other links) */
  jerseyNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  /**   recruiter fields */
  nickname?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<Scalars['String']>;
  raceEthnicity?: InputMaybe<Scalars['String']>;
  /**   recruitClass: String */
  recruitGender?: InputMaybe<Scalars['String']>;
  recruitSport?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  sports?: InputMaybe<Array<InputMaybe<InputSport>>>;
  state?: InputMaybe<Scalars['String']>;
  /**   (Adding a link to their schedule) */
  stats?: InputMaybe<Scalars['String']>;
  tenure?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  trainerInfo?: InputMaybe<CoachInfoInput>;
  transcript?: InputMaybe<TranscriptInput>;
  weight?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
}

/**  ######################### INPUTS ########################### */
export interface Invitation {
  __typename?: 'Invitation';
  createdAt?: Maybe<Scalars['AWSTimestamp']>;
  id: Scalars['ID'];
  inviter: User;
  subjectNode: InvitationSubjectNode;
  type: InvitationType;
}

export type InvitationSubjectNode = SportProgram | Team;

export enum InvitationType {
  InvitationToProgram = 'INVITATION_TO_PROGRAM',
  InvitationToTeam = 'INVITATION_TO_TEAM'
}

export interface InviteUserToProgramInput {
  programId: Scalars['ID'];
  userEmail: Scalars['AWSEmail'];
}

export interface InviteUserToTeamInput {
  inviteeId: Scalars['ID'];
  teamId: Scalars['ID'];
}

export interface IsProgramSubscribedInput {
  division: Scalars['String'];
  organization: OrganizationInput;
  recruitGender: Scalars['String'];
  sport: Scalars['String'];
}

export interface LikedVideo {
  __typename?: 'LikedVideo';
  id: Scalars['ID'];
  liked: Scalars['Boolean'];
  likedAt?: Maybe<Scalars['AWSTimestamp']>;
}

export interface Location {
  __typename?: 'Location';
  address: Scalars['String'];
  city: Scalars['String'];
  id: Scalars['ID'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
}

export enum LogicalOperator {
  And = 'AND',
  Or = 'OR'
}

export interface Match {
  __typename?: 'Match';
  court: Scalars['String'];
  /**   depriciate */
  endTime?: Maybe<Scalars['AWSTimestamp']>;
  id: Scalars['ID'];
  schedule?: Maybe<Scalars['AWSTimestamp']>;
  startTime?: Maybe<Scalars['AWSTimestamp']>;
  teams: Array<Team>;
  videos: Array<Maybe<Content>>;
  /**   depriciate */
  winner?: Maybe<Team>;
}

export interface Mutation {
  __typename?: 'Mutation';
  addUserToWatchlistGroup: WatchlistGroup;
  blockUser: User;
  /**   only Recruiter can call */
  cancelSubscription: SubscriptionData;
  cancelUserInviteInProgram: GeneralResponse;
  checkInToEvent: CheckInToEventOutput;
  claimReward: GeneralResponse;
  createComment: Comment;
  createConversation?: Maybe<Array<Maybe<Conversation>>>;
  createDirectMessage?: Maybe<DirectMessage>;
  createUser: User;
  createWatchlistGroup: WatchlistGroup;
  deleteUser: User;
  deleteVideo: Id;
  deleteWatchlistGroup: WatchlistGroup;
  followUser: FollowedUser;
  /**   only Athlete can call */
  handleDocumentsRequest: Scalars['String'];
  handleInvitationToProgram: GeneralResponse;
  handleInvitationToTeam: User;
  handleOrganizationOffer: Organization;
  inviteUserToProgram: GeneralResponse;
  /**
   *   transferProgramAdminShipToMember(input: TransferProgramAdminShipToMemberInput!): GeneralResponse!
   *  adjustUserRoleInProgram(input: AdjustUserRoleInProgramInput!): GeneralResponse!
   */
  inviteUserToTeam: GeneralResponse;
  likeVideo: LikedVideo;
  pinWatchlistGroup: WatchlistGroup;
  purchaseReward: PurchaseRewardOutput;
  /**   only Recruiter can call */
  purchaseSubscription: SubscriptionData;
  readNotification: Scalars['String'];
  registerTeam: RegisterTeamOutput;
  removeUserFromProgram: GeneralResponse;
  removeUserFromWatchlistGroup: WatchlistGroup;
  /**   only Recruiter can call */
  requestDocuments: Scalars['String'];
  trackShare: PostShare;
  trackView: View;
  updateTeam: Team;
  updateUser: User;
  uploadContent: Content;
}


export interface MutationAddUserToWatchlistGroupArgs {
  input: AddUserToWatchlistGroupInput;
}


export interface MutationBlockUserArgs {
  id: Scalars['ID'];
  isBlocked?: InputMaybe<Scalars['Boolean']>;
}


export interface MutationCancelSubscriptionArgs {
  subscriptionId: Scalars['String'];
}


export interface MutationCancelUserInviteInProgramArgs {
  input: CancelUserInviteInProgramInput;
}


export interface MutationCheckInToEventArgs {
  input: CheckedInToEventInput;
}


export interface MutationClaimRewardArgs {
  input: ClaimRewardInput;
}


export interface MutationCreateCommentArgs {
  comment: Scalars['String'];
  videoId: Scalars['ID'];
}


export interface MutationCreateConversationArgs {
  input: Array<InputMaybe<CreateConversationInput>>;
}


export interface MutationCreateDirectMessageArgs {
  input: CreateDirectMessageInput;
}


export interface MutationCreateUserArgs {
  input: InputUser;
}


export interface MutationCreateWatchlistGroupArgs {
  input: CreateWatchlistGroupInput;
}


export interface MutationDeleteUserArgs {
  adminInput?: InputMaybe<DeleteUserByAdminInput>;
}


export interface MutationDeleteVideoArgs {
  id: Scalars['ID'];
}


export interface MutationDeleteWatchlistGroupArgs {
  input: DeleteWatchlistGroupInput;
}


export interface MutationFollowUserArgs {
  id: Scalars['ID'];
}


export interface MutationHandleDocumentsRequestArgs {
  allow: Scalars['Boolean'];
  recruiterId: Scalars['ID'];
  requestId: Scalars['ID'];
}


export interface MutationHandleInvitationToProgramArgs {
  input: HandleInvitationToProgramInput;
}


export interface MutationHandleInvitationToTeamArgs {
  input: HandleInvitationToTeamInput;
}


export interface MutationHandleOrganizationOfferArgs {
  input: HandleOrganizationOfferInput;
}


export interface MutationInviteUserToProgramArgs {
  input: InviteUserToProgramInput;
}


export interface MutationInviteUserToTeamArgs {
  input: InviteUserToTeamInput;
}


export interface MutationLikeVideoArgs {
  id: Scalars['ID'];
}


export interface MutationPinWatchlistGroupArgs {
  input: PinWatchlistGroupInput;
}


export interface MutationPurchaseRewardArgs {
  input: PurchaseRewardInput;
}


export interface MutationPurchaseSubscriptionArgs {
  input: PurchaseSubscriptionInput;
}


export interface MutationReadNotificationArgs {
  action?: InputMaybe<NotificationReadAction>;
  id?: InputMaybe<Scalars['ID']>;
}


export interface MutationRegisterTeamArgs {
  input: RegisterTeamInput;
}


export interface MutationRemoveUserFromProgramArgs {
  input: RemoveUserFromProgramInput;
}


export interface MutationRemoveUserFromWatchlistGroupArgs {
  input: RemoveUserFromWatchlistGroup;
}


export interface MutationRequestDocumentsArgs {
  athleteId: Scalars['ID'];
}


export interface MutationTrackShareArgs {
  input: TrackShareInput;
}


export interface MutationTrackViewArgs {
  id: Scalars['ID'];
  type: Scalars['String'];
}


export interface MutationUpdateTeamArgs {
  input: UpdateTeamInput;
}


export interface MutationUpdateUserArgs {
  input: InputUser;
}


export interface MutationUploadContentArgs {
  input?: InputMaybe<ContentInput>;
}

export interface Notification {
  __typename?: 'Notification';
  action: NotificationAction;
  actor?: Maybe<User>;
  actorTitle: Scalars['String'];
  createdAt: Scalars['AWSTimestamp'];
  id: Scalars['ID'];
  message: Scalars['String'];
  read: Scalars['Boolean'];
  receiverId: Scalars['String'];
  /**   actor */
  subjectNodeId: Scalars['ID'];
}

export enum NotificationAction {
  AddToWatchlist = 'ADD_TO_WATCHLIST',
  CancelProgramInviteRequest = 'CANCEL_PROGRAM_INVITE_REQUEST',
  Comment = 'COMMENT',
  DocumentRequest = 'DOCUMENT_REQUEST',
  DocumentRequestHandle = 'DOCUMENT_REQUEST_HANDLE',
  FollowUser = 'FOLLOW_USER',
  LikeVideo = 'LIKE_VIDEO',
  MentionInComment = 'MENTION_IN_COMMENT',
  MentionInVideoCaption = 'MENTION_IN_VIDEO_CAPTION',
  Others = 'OTHERS',
  ProfileView = 'PROFILE_VIEW',
  ProgramInviteRequest = 'PROGRAM_INVITE_REQUEST',
  ProgramInviteRequestHandle = 'PROGRAM_INVITE_REQUEST_HANDLE',
  RemoveFromProgram = 'REMOVE_FROM_PROGRAM',
  SendMessage = 'SEND_MESSAGE',
  SubscriptionActivate = 'SUBSCRIPTION_ACTIVATE',
  SubscriptionCancel = 'SUBSCRIPTION_CANCEL',
  TeamInviteRequest = 'TEAM_INVITE_REQUEST',
  VideoView = 'VIDEO_VIEW'
}

export enum NotificationReadAction {
  All = 'ALL',
  Single = 'SINGLE'
}

export interface NotificationsListOutput {
  __typename?: 'NotificationsListOutput';
  nextToken?: Maybe<Scalars['String']>;
  /**
   *   title: String
   *  data: [Notification]
   */
  notifications: Array<Maybe<Notification>>;
}

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

export interface Organization {
  __typename?: 'Organization';
  city?: Maybe<Scalars['String']>;
  conference?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  programs?: Maybe<Array<Maybe<SportProgram>>>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
}

export interface OrganizationInput {
  city: Scalars['String'];
  conference?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  state: Scalars['String'];
  zipCode?: InputMaybe<Scalars['String']>;
}

export interface Pagination {
  pgNo?: InputMaybe<Scalars['Int']>;
  pgSize?: InputMaybe<Scalars['Int']>;
}

export interface PinWatchlistGroupInput {
  groupId: Scalars['ID'];
  pin: Scalars['Boolean'];
}

export interface PostShare {
  __typename?: 'PostShare';
  count: Scalars['Int'];
  id: Scalars['ID'];
}

export interface ProgramMember {
  __typename?: 'ProgramMember';
  inviteAccepted: Scalars['Boolean'];
  user: User;
}

export interface Purchase {
  acknowledged?: InputMaybe<Scalars['Boolean']>;
  orderId: Scalars['String'];
  originalOrderId?: InputMaybe<Scalars['String']>;
  originalPurchaseTime?: InputMaybe<Scalars['AWSTimestamp']>;
  packageName?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  purchaseState?: InputMaybe<Scalars['String']>;
  purchaseTime: Scalars['AWSTimestamp'];
  purchaseToken?: InputMaybe<Scalars['String']>;
  transactionReceipt?: InputMaybe<Scalars['String']>;
}

export interface PurchaseRewardInput {
  quantity?: InputMaybe<Scalars['Int']>;
  rewardId: Scalars['ID'];
}

export interface PurchaseRewardOutput {
  __typename?: 'PurchaseRewardOutput';
  message: Scalars['String'];
  scoreBoardDetails: ScoreBoardDetails;
}

export interface PurchaseSubscriptionInput {
  plan: SubscriptionPlan;
  promoCode?: InputMaybe<Scalars['String']>;
}

export interface Query {
  __typename?: 'Query';
  checkBlockedUsers: Array<Maybe<Scalars['ID']>>;
  getBlockedUsers: Array<Maybe<User>>;
  getComments: Array<Maybe<UserComment>>;
  getConversationId?: Maybe<Scalars['String']>;
  getConversations?: Maybe<Array<Maybe<Conversation>>>;
  getDirectMessages?: Maybe<Array<Maybe<DirectMessage>>>;
  /**   only Athlete can call */
  getDocumentRequests: Array<Maybe<DocumentRequest>>;
  getEvents: EventsList;
  getFollows: Array<Maybe<User>>;
  /**   getProgram(input: GetProgramInput!): SportProgram! */
  getInvitations: Array<Maybe<Invitation>>;
  /**   getNotifications(count:Int!, nextToken:String): [NotificationSummary]! */
  getNotifications: NotificationsListOutput;
  getScoreBoardDetails: ScoreBoardDetails;
  getSubscriptionPrice: SubscriptionPrice;
  getTeamRegistrationPrice: Scalars['Float'];
  getTeams: TeamsList;
  getUser: User;
  getUserToken: TokenUser;
  getVideoStats: Array<Maybe<User>>;
  getVideoViewers: UsersList;
  getVideos: ContentList;
  getWatchlistGroups: Array<Maybe<WatchlistGroup>>;
  /**   only Recruiter can call */
  isAllowedToViewDocuments: Scalars['Boolean'];
  isProgramSubscribed: Scalars['Boolean'];
  notifyUser: Scalars['String'];
  searchOrganizations: Array<Maybe<Organization>>;
  searchUsers: Array<Maybe<User>>;
  verifyPurchase: SubscriptionData;
}


export interface QueryGetCommentsArgs {
  videoId: Scalars['ID'];
}


export interface QueryGetConversationIdArgs {
  firstUser: Scalars['String'];
  secondUser: Scalars['String'];
}


export interface QueryGetDirectMessagesArgs {
  pk: Scalars['String'];
}


export interface QueryGetEventsArgs {
  input?: InputMaybe<GetEventsInput>;
}


export interface QueryGetFollowsArgs {
  id: Scalars['ID'];
  type: Scalars['String'];
}


export interface QueryGetNotificationsArgs {
  count: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
  notificationId?: InputMaybe<Scalars['ID']>;
}


export interface QueryGetSubscriptionPriceArgs {
  input: GetSubscriptionPriceInput;
}


export interface QueryGetTeamsArgs {
  input?: InputMaybe<GetTeamsInput>;
}


export interface QueryGetUserArgs {
  id: Scalars['ID'];
}


export interface QueryGetUserTokenArgs {
  id: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
}


export interface QueryGetVideoStatsArgs {
  id: Scalars['ID'];
  type: Scalars['String'];
}


export interface QueryGetVideoViewersArgs {
  input: GetVideoViewersInput;
}


export interface QueryGetVideosArgs {
  input?: InputMaybe<VideoSearchInput>;
}


export interface QueryGetWatchlistGroupsArgs {
  input: GetWatchlistGroupsInput;
}


export interface QueryIsAllowedToViewDocumentsArgs {
  athleteId: Scalars['ID'];
}


export interface QueryIsProgramSubscribedArgs {
  input: IsProgramSubscribedInput;
}


export interface QueryNotifyUserArgs {
  action: NotificationAction;
  message?: InputMaybe<Scalars['String']>;
  subjectNodeId: Scalars['ID'];
  userId: Scalars['ID'];
}


export interface QuerySearchOrganizationsArgs {
  input: SearchOrganizationsInput;
}


export interface QuerySearchUsersArgs {
  input: SearchUserInput;
}


export interface QueryVerifyPurchaseArgs {
  platform: Scalars['String'];
  purchase: Purchase;
}

export interface RegisterTeamInput {
  coaches: Array<InputUser>;
  eventId: Scalars['String'];
  name: Scalars['String'];
  /**   Recruiter */
  players: Array<InputUser>;
}

export interface RegisterTeamOutput {
  __typename?: 'RegisterTeamOutput';
  paymentReceipt?: Maybe<TeamRegistrationReceipt>;
  team?: Maybe<Team>;
}

export interface RelativePersonInfo {
  __typename?: 'RelativePersonInfo';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  relation?: Maybe<Scalars['String']>;
}

export interface RelativePersonInfoInput {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  relation?: InputMaybe<Scalars['String']>;
}

export interface RemoveUserFromProgramInput {
  programId: Scalars['ID'];
  userId: Scalars['ID'];
}

export interface RemoveUserFromWatchlistGroup {
  groupId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
}

export interface Reward {
  __typename?: 'Reward';
  costPoints: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  quantityPurchased: Scalars['Int'];
}

export enum RewardClaimFor {
  Notification = 'NOTIFICATION',
  VideoViews = 'VIDEO_VIEWS'
}

export interface ScoreBoardDetails {
  __typename?: 'ScoreBoardDetails';
  engagementPoints: Scalars['Int'];
  rewards: Array<Reward>;
}

export interface SearchOrganizationsInput {
  name: Scalars['String'];
}

export interface SearchUserInput {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRole>;
}

export interface SortBy {
  field: Scalars['String'];
  order: Order;
}

export interface Sport {
  __typename?: 'Sport';
  class?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  sport?: Maybe<Scalars['String']>;
}

export enum SportEnum {
  Baseball = 'Baseball',
  Basketball = 'Basketball',
  Football = 'Football',
  Hockey = 'Hockey',
  Soccer = 'Soccer',
  Softball = 'Softball',
  TrackNField = 'Track_N_Field',
  Volleyball = 'Volleyball',
  Wrestling = 'Wrestling'
}

export interface SportProgram {
  __typename?: 'SportProgram';
  admin?: Maybe<User>;
  division: Scalars['String'];
  id: Scalars['ID'];
  /**   editors: [User] */
  members: Array<Maybe<ProgramMember>>;
  organization?: Maybe<Organization>;
  recruitGender: Scalars['String'];
  seats?: Maybe<Scalars['Int']>;
  sport: Scalars['String'];
}

export interface Subscription {
  __typename?: 'Subscription';
  createDirectMessage?: Maybe<DirectMessage>;
}


export interface SubscriptionCreateDirectMessageArgs {
  pk: Scalars['String'];
}

export interface SubscriptionData {
  __typename?: 'SubscriptionData';
  clientSecret?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['AWSTimestamp']>;
  expirationDate?: Maybe<Scalars['AWSTimestamp']>;
  plan?: Maybe<SubscriptionPlan>;
  price?: Maybe<Scalars['Float']>;
  status?: Maybe<SubscriptionStatus>;
  subscriptionId?: Maybe<Scalars['String']>;
}

export enum SubscriptionPlan {
  RecruiterProPlan = 'RECRUITER_PRO_PLAN'
}

export interface SubscriptionPrice {
  __typename?: 'SubscriptionPrice';
  amount: Scalars['Float'];
  discount?: Maybe<Discount>;
}

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid'
}

export interface Team {
  __typename?: 'Team';
  coaches?: Maybe<Array<Maybe<TeamMember>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  /**   athletes */
  participated?: Maybe<Scalars['Boolean']>;
  /**   recruiters */
  players?: Maybe<Array<TeamMember>>;
  /**   recruiter | Team Creator */
  registeredAt?: Maybe<Scalars['AWSTimestamp']>;
  registeredBy?: Maybe<User>;
  score?: Maybe<Scalars['Int']>;
}

export interface TeamMember {
  __typename?: 'TeamMember';
  checkedIn?: Maybe<Scalars['Boolean']>;
  info: User;
  inviteSent?: Maybe<Scalars['Boolean']>;
}

export interface TeamRegistrationReceipt {
  __typename?: 'TeamRegistrationReceipt';
  clientSecret: Scalars['String'];
  /**
   *   receiptId: ID!
   *  creationDate: AWSTimestamp!
   *  expirationDate: AWSTimestamp!
   */
  price: Scalars['Float'];
}

export interface TeamsList {
  __typename?: 'TeamsList';
  items: Array<Maybe<Team>>;
  total: Scalars['Int'];
}

export interface TokenUser {
  __typename?: 'TokenUser';
  id: Scalars['ID'];
}

export interface TrackShareInput {
  videoId: Scalars['ID'];
}

export interface Transcript {
  __typename?: 'Transcript';
  name: Scalars['String'];
  uri: Scalars['String'];
}

export interface TranscriptInput {
  name: Scalars['String'];
  uri: Scalars['String'];
}

export interface UpdateTeamInput {
  coaches: Array<InputUser>;
  name: Scalars['String'];
  /**   Recruiter */
  players: Array<InputUser>;
  teamId: Scalars['ID'];
}

export interface User {
  __typename?: 'User';
  about?: Maybe<Scalars['String']>;
  /**   (Adding a link to their stats) */
  accolades?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  clubCoachInfo?: Maybe<CoachInfo>;
  clubOrganization?: Maybe<Scalars['String']>;
  currentSchool?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /**   this field can be seen by recuiters, if the athlete is pinned in their watchList */
  engagementPoints?: Maybe<Scalars['Int']>;
  /**   (Adding link to Game Film)  */
  favoriteFood?: Maybe<Scalars['String']>;
  favoriteHobby?: Maybe<Scalars['String']>;
  /**   fan fields */
  favoriteSport?: Maybe<Scalars['String']>;
  favoriteSportToWatch?: Maybe<Scalars['String']>;
  favoriteVacation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  followed?: Maybe<Scalars['Boolean']>;
  /**   this field can be seen by every user type to see if the user is in their following list */
  followerCount?: Maybe<Scalars['Int']>;
  followingCount?: Maybe<Scalars['Int']>;
  funFact?: Maybe<Scalars['String']>;
  /**   (Adding link to Game Film)  */
  gameFilm?: Maybe<Scalars['String']>;
  /**   (adding organization name) */
  gameSchedule?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  gpa?: Maybe<Scalars['String']>;
  guardians?: Maybe<Array<Maybe<RelativePersonInfo>>>;
  /**   athlete fields */
  height?: Maybe<Scalars['String']>;
  /**   (S3 file path) */
  highSchoolCoachInfo?: Maybe<CoachInfo>;
  hsGradYear?: Maybe<Scalars['String']>;
  /**   common fields for fan, athlete and recruiter */
  id: Scalars['ID'];
  /**   if there is some new notifications then this will be true */
  isDeleted?: Maybe<Scalars['Boolean']>;
  isOnboarded?: Maybe<Scalars['Boolean']>;
  /**   (Adding a link to news articles or other links) */
  jerseyNumber?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /**   profile views count */
  newNotification?: Maybe<Scalars['Boolean']>;
  /**   recruiter fields */
  nickname?: Maybe<Scalars['String']>;
  offers?: Maybe<Array<Maybe<Organization>>>;
  organization?: Maybe<Organization>;
  phoneNumber?: Maybe<Scalars['String']>;
  /**   shows that how many recruiters watching the athlete */
  pinnedInWatchlist?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  program?: Maybe<SportProgram>;
  pushTokens?: Maybe<Scalars['String']>;
  raceEthnicity?: Maybe<Scalars['String']>;
  /**   recruitClass: String */
  recruitGender?: Maybe<Scalars['String']>;
  /**   prefered gender in his team */
  recruitSport?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  sports?: Maybe<Array<Maybe<Sport>>>;
  state?: Maybe<Scalars['String']>;
  /**   (Adding a link to their schedule) */
  stats?: Maybe<Scalars['String']>;
  /**   shows that how many athletes is in the recruiter's watch list  */
  subscription?: Maybe<SubscriptionData>;
  tenure?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trainerInfo?: Maybe<CoachInfo>;
  transcript?: Maybe<Transcript>;
  viewCount?: Maybe<Scalars['Int']>;
  watched?: Maybe<Scalars['Boolean']>;
  /**   this field can be seen by recuiters, if the athlete is added in their watchList */
  watcherCount?: Maybe<Scalars['Int']>;
  watchingCount?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
}

export interface UserComment {
  __typename?: 'UserComment';
  comment: Scalars['String'];
  createdAt: Scalars['AWSTimestamp'];
  user: User;
  videoId: Scalars['ID'];
}

/**  ######################### ENUMS ########################### */
export enum UserRole {
  Athlete = 'athlete',
  Fan = 'fan',
  ProgramInvitee = 'programInvitee',
  Recruiter = 'recruiter'
}

export interface UsersList {
  __typename?: 'UsersList';
  count: Scalars['Int'];
  items: Array<Maybe<User>>;
}

export enum ValueType {
  Int = 'INT',
  Str = 'STR'
}

export interface View {
  __typename?: 'View';
  id: Scalars['ID'];
  viewCount?: Maybe<Scalars['Int']>;
}

export interface WatchlistGroup {
  __typename?: 'WatchlistGroup';
  createdAt?: Maybe<Scalars['AWSTimestamp']>;
  id: Scalars['ID'];
  members?: Maybe<Array<Maybe<User>>>;
  membersCount?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  pinned: Scalars['Boolean'];
  pinnedAt?: Maybe<Scalars['AWSTimestamp']>;
}

export interface ContentInput {
  caption?: InputMaybe<Scalars['String']>;
  filetype: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
  sport: Scalars['String'];
  thumbnailKey?: InputMaybe<Scalars['String']>;
}

export interface InputSport {
  class?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  sport?: InputMaybe<Scalars['String']>;
}

export interface SearchFilter {
  age?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<Scalars['String']>;
  gpa?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['String']>;
  hsGradYear?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  sport: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
}

export interface VideoSearchInput {
  /**   refers to match of an event */
  eventId?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<SearchFilter>;
  following?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  matchId?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Pagination>;
  range?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /**   refers to event */
  trending?: InputMaybe<Scalars['Boolean']>;
  /**   refers to user id */
  videoId?: InputMaybe<Scalars['ID']>;
}



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AWSEmail: ResolverTypeWrapper<Scalars['AWSEmail']>;
  AWSTimestamp: ResolverTypeWrapper<Scalars['AWSTimestamp']>;
  Action: Action;
  AddUserToWatchlistGroupInput: AddUserToWatchlistGroupInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  CancelUserInviteInProgramInput: CancelUserInviteInProgramInput;
  CheckInToEventOutput: ResolverTypeWrapper<CheckInToEventOutput>;
  CheckedInToEventInput: CheckedInToEventInput;
  ClaimRewardInput: ClaimRewardInput;
  CoachInfo: ResolverTypeWrapper<CoachInfo>;
  CoachInfoInput: CoachInfoInput;
  Comment: ResolverTypeWrapper<Comment>;
  ComparisonOperators: ComparisonOperators;
  Content: ResolverTypeWrapper<Omit<Content, 'owner'> & { owner?: Maybe<ResolversTypes['ContentOwner']> }>;
  ContentList: ResolverTypeWrapper<ContentList>;
  ContentOwner: ResolversTypes['Event'] | ResolversTypes['User'];
  Conversation: ResolverTypeWrapper<Conversation>;
  CreateConversationInput: CreateConversationInput;
  CreateDirectMessageInput: CreateDirectMessageInput;
  CreateWatchlistGroupInput: CreateWatchlistGroupInput;
  DeleteUserByAdminInput: DeleteUserByAdminInput;
  DeleteUserInput: DeleteUserInput;
  DeleteWatchlistGroupInput: DeleteWatchlistGroupInput;
  DirectMessage: ResolverTypeWrapper<DirectMessage>;
  Discount: ResolverTypeWrapper<Discount>;
  DivisionEnum: DivisionEnum;
  DocumentRequest: ResolverTypeWrapper<DocumentRequest>;
  Event: ResolverTypeWrapper<Event>;
  EventsList: ResolverTypeWrapper<EventsList>;
  FilterField: FilterField;
  FilterOptions: FilterOptions;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  FollowedUser: ResolverTypeWrapper<FollowedUser>;
  GenderEnum: GenderEnum;
  GeneralResponse: ResolverTypeWrapper<GeneralResponse>;
  GetEventsInput: GetEventsInput;
  GetSubscriptionPriceInput: GetSubscriptionPriceInput;
  GetTeamsInput: GetTeamsInput;
  GetVideoViewersInput: GetVideoViewersInput;
  GetWatchlistGroupsInput: GetWatchlistGroupsInput;
  HandleInvitationToProgramInput: HandleInvitationToProgramInput;
  HandleInvitationToTeamInput: HandleInvitationToTeamInput;
  HandleOrganizationOfferInput: HandleOrganizationOfferInput;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Id: ResolverTypeWrapper<Id>;
  InputUser: InputUser;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Invitation: ResolverTypeWrapper<Omit<Invitation, 'subjectNode'> & { subjectNode: ResolversTypes['InvitationSubjectNode'] }>;
  InvitationSubjectNode: ResolversTypes['SportProgram'] | ResolversTypes['Team'];
  InvitationType: InvitationType;
  InviteUserToProgramInput: InviteUserToProgramInput;
  InviteUserToTeamInput: InviteUserToTeamInput;
  IsProgramSubscribedInput: IsProgramSubscribedInput;
  LikedVideo: ResolverTypeWrapper<LikedVideo>;
  Location: ResolverTypeWrapper<Location>;
  LogicalOperator: LogicalOperator;
  Match: ResolverTypeWrapper<Match>;
  Mutation: ResolverTypeWrapper<{}>;
  Notification: ResolverTypeWrapper<Notification>;
  NotificationAction: NotificationAction;
  NotificationReadAction: NotificationReadAction;
  NotificationsListOutput: ResolverTypeWrapper<NotificationsListOutput>;
  Order: Order;
  Organization: ResolverTypeWrapper<Organization>;
  OrganizationInput: OrganizationInput;
  Pagination: Pagination;
  PinWatchlistGroupInput: PinWatchlistGroupInput;
  PostShare: ResolverTypeWrapper<PostShare>;
  ProgramMember: ResolverTypeWrapper<ProgramMember>;
  Purchase: Purchase;
  PurchaseRewardInput: PurchaseRewardInput;
  PurchaseRewardOutput: ResolverTypeWrapper<PurchaseRewardOutput>;
  PurchaseSubscriptionInput: PurchaseSubscriptionInput;
  Query: ResolverTypeWrapper<{}>;
  RegisterTeamInput: RegisterTeamInput;
  RegisterTeamOutput: ResolverTypeWrapper<RegisterTeamOutput>;
  RelativePersonInfo: ResolverTypeWrapper<RelativePersonInfo>;
  RelativePersonInfoInput: RelativePersonInfoInput;
  RemoveUserFromProgramInput: RemoveUserFromProgramInput;
  RemoveUserFromWatchlistGroup: RemoveUserFromWatchlistGroup;
  Reward: ResolverTypeWrapper<Reward>;
  RewardClaimFor: RewardClaimFor;
  ScoreBoardDetails: ResolverTypeWrapper<ScoreBoardDetails>;
  SearchOrganizationsInput: SearchOrganizationsInput;
  SearchUserInput: SearchUserInput;
  SortBy: SortBy;
  Sport: ResolverTypeWrapper<Sport>;
  SportEnum: SportEnum;
  SportProgram: ResolverTypeWrapper<SportProgram>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Subscription: ResolverTypeWrapper<{}>;
  SubscriptionData: ResolverTypeWrapper<SubscriptionData>;
  SubscriptionPlan: SubscriptionPlan;
  SubscriptionPrice: ResolverTypeWrapper<SubscriptionPrice>;
  SubscriptionStatus: SubscriptionStatus;
  Team: ResolverTypeWrapper<Team>;
  TeamMember: ResolverTypeWrapper<TeamMember>;
  TeamRegistrationReceipt: ResolverTypeWrapper<TeamRegistrationReceipt>;
  TeamsList: ResolverTypeWrapper<TeamsList>;
  TokenUser: ResolverTypeWrapper<TokenUser>;
  TrackShareInput: TrackShareInput;
  Transcript: ResolverTypeWrapper<Transcript>;
  TranscriptInput: TranscriptInput;
  UpdateTeamInput: UpdateTeamInput;
  User: ResolverTypeWrapper<User>;
  UserComment: ResolverTypeWrapper<UserComment>;
  UserRole: UserRole;
  UsersList: ResolverTypeWrapper<UsersList>;
  ValueType: ValueType;
  View: ResolverTypeWrapper<View>;
  WatchlistGroup: ResolverTypeWrapper<WatchlistGroup>;
  contentInput: ContentInput;
  inputSport: InputSport;
  searchFilter: SearchFilter;
  videoSearchInput: VideoSearchInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AWSEmail: Scalars['AWSEmail'];
  AWSTimestamp: Scalars['AWSTimestamp'];
  AddUserToWatchlistGroupInput: AddUserToWatchlistGroupInput;
  Boolean: Scalars['Boolean'];
  CancelUserInviteInProgramInput: CancelUserInviteInProgramInput;
  CheckInToEventOutput: CheckInToEventOutput;
  CheckedInToEventInput: CheckedInToEventInput;
  ClaimRewardInput: ClaimRewardInput;
  CoachInfo: CoachInfo;
  CoachInfoInput: CoachInfoInput;
  Comment: Comment;
  Content: Omit<Content, 'owner'> & { owner?: Maybe<ResolversParentTypes['ContentOwner']> };
  ContentList: ContentList;
  ContentOwner: ResolversParentTypes['Event'] | ResolversParentTypes['User'];
  Conversation: Conversation;
  CreateConversationInput: CreateConversationInput;
  CreateDirectMessageInput: CreateDirectMessageInput;
  CreateWatchlistGroupInput: CreateWatchlistGroupInput;
  DeleteUserByAdminInput: DeleteUserByAdminInput;
  DeleteUserInput: DeleteUserInput;
  DeleteWatchlistGroupInput: DeleteWatchlistGroupInput;
  DirectMessage: DirectMessage;
  Discount: Discount;
  DocumentRequest: DocumentRequest;
  Event: Event;
  EventsList: EventsList;
  FilterField: FilterField;
  FilterOptions: FilterOptions;
  Float: Scalars['Float'];
  FollowedUser: FollowedUser;
  GeneralResponse: GeneralResponse;
  GetEventsInput: GetEventsInput;
  GetSubscriptionPriceInput: GetSubscriptionPriceInput;
  GetTeamsInput: GetTeamsInput;
  GetVideoViewersInput: GetVideoViewersInput;
  GetWatchlistGroupsInput: GetWatchlistGroupsInput;
  HandleInvitationToProgramInput: HandleInvitationToProgramInput;
  HandleInvitationToTeamInput: HandleInvitationToTeamInput;
  HandleOrganizationOfferInput: HandleOrganizationOfferInput;
  ID: Scalars['ID'];
  Id: Id;
  InputUser: InputUser;
  Int: Scalars['Int'];
  Invitation: Omit<Invitation, 'subjectNode'> & { subjectNode: ResolversParentTypes['InvitationSubjectNode'] };
  InvitationSubjectNode: ResolversParentTypes['SportProgram'] | ResolversParentTypes['Team'];
  InviteUserToProgramInput: InviteUserToProgramInput;
  InviteUserToTeamInput: InviteUserToTeamInput;
  IsProgramSubscribedInput: IsProgramSubscribedInput;
  LikedVideo: LikedVideo;
  Location: Location;
  Match: Match;
  Mutation: {};
  Notification: Notification;
  NotificationsListOutput: NotificationsListOutput;
  Organization: Organization;
  OrganizationInput: OrganizationInput;
  Pagination: Pagination;
  PinWatchlistGroupInput: PinWatchlistGroupInput;
  PostShare: PostShare;
  ProgramMember: ProgramMember;
  Purchase: Purchase;
  PurchaseRewardInput: PurchaseRewardInput;
  PurchaseRewardOutput: PurchaseRewardOutput;
  PurchaseSubscriptionInput: PurchaseSubscriptionInput;
  Query: {};
  RegisterTeamInput: RegisterTeamInput;
  RegisterTeamOutput: RegisterTeamOutput;
  RelativePersonInfo: RelativePersonInfo;
  RelativePersonInfoInput: RelativePersonInfoInput;
  RemoveUserFromProgramInput: RemoveUserFromProgramInput;
  RemoveUserFromWatchlistGroup: RemoveUserFromWatchlistGroup;
  Reward: Reward;
  ScoreBoardDetails: ScoreBoardDetails;
  SearchOrganizationsInput: SearchOrganizationsInput;
  SearchUserInput: SearchUserInput;
  SortBy: SortBy;
  Sport: Sport;
  SportProgram: SportProgram;
  String: Scalars['String'];
  Subscription: {};
  SubscriptionData: SubscriptionData;
  SubscriptionPrice: SubscriptionPrice;
  Team: Team;
  TeamMember: TeamMember;
  TeamRegistrationReceipt: TeamRegistrationReceipt;
  TeamsList: TeamsList;
  TokenUser: TokenUser;
  TrackShareInput: TrackShareInput;
  Transcript: Transcript;
  TranscriptInput: TranscriptInput;
  UpdateTeamInput: UpdateTeamInput;
  User: User;
  UserComment: UserComment;
  UsersList: UsersList;
  View: View;
  WatchlistGroup: WatchlistGroup;
  contentInput: ContentInput;
  inputSport: InputSport;
  searchFilter: SearchFilter;
  videoSearchInput: VideoSearchInput;
};

export type Aws_Api_KeyDirectiveArgs = { };

export type Aws_Api_KeyDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_Api_KeyDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_AuthDirectiveArgs = {
  cognito_groups?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Aws_AuthDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_AuthDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_Cognito_User_PoolsDirectiveArgs = {
  cognito_groups?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Aws_Cognito_User_PoolsDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_Cognito_User_PoolsDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_IamDirectiveArgs = { };

export type Aws_IamDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_IamDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_LambdaDirectiveArgs = { };

export type Aws_LambdaDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_LambdaDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_OidcDirectiveArgs = { };

export type Aws_OidcDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_OidcDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_PublishDirectiveArgs = {
  subscriptions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Aws_PublishDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_PublishDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_SubscribeDirectiveArgs = {
  mutations?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Aws_SubscribeDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_SubscribeDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type DeferDirectiveArgs = { };

export type DeferDirectiveResolver<Result, Parent, ContextType = any, Args = DeferDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export interface AwsEmailScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSEmail'], any> {
  name: 'AWSEmail';
}

export interface AwsTimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSTimestamp'], any> {
  name: 'AWSTimestamp';
}

export type CheckInToEventOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckInToEventOutput'] = ResolversParentTypes['CheckInToEventOutput']> = {
  eventAttendee?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  eventId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoachInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachInfo'] = ResolversParentTypes['CoachInfo']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Comment'] = ResolversParentTypes['Comment']> = {
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSTimestamp'], ParentType, ContextType>;
  videoId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Content'] = ResolversParentTypes['Content']> = {
  caption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commentCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  filetype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  likeCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  liked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['ContentOwner']>, ParentType, ContextType>;
  shareCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uploadedDate?: Resolver<Maybe<ResolversTypes['AWSTimestamp']>, ParentType, ContextType>;
  viewCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentListResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentList'] = ResolversParentTypes['ContentList']> = {
  items?: Resolver<Array<Maybe<ResolversTypes['Content']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentOwnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentOwner'] = ResolversParentTypes['ContentOwner']> = {
  __resolveType: TypeResolveFn<'Event' | 'User', ParentType, ContextType>;
};

export type ConversationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Conversation'] = ResolversParentTypes['Conversation']> = {
  lastMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pk?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recipient?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sk?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DirectMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['DirectMessage'] = ResolversParentTypes['DirectMessage']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pk?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  receiverUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  senderUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sk?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountResolvers<ContextType = any, ParentType extends ResolversParentTypes['Discount'] = ResolversParentTypes['Discount']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  amountOff?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentOff?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentRequest'] = ResolversParentTypes['DocumentRequest']> = {
  createdAt?: Resolver<ResolversTypes['AWSTimestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  recruiter?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventResolvers<ContextType = any, ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event']> = {
  attendees?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  checkedIn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  contact?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSTimestamp'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  division?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['AWSTimestamp']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['GenderEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  matches?: Resolver<Maybe<Array<Maybe<ResolversTypes['Match']>>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  schedule?: Resolver<Maybe<ResolversTypes['AWSTimestamp']>, ParentType, ContextType>;
  sport?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['AWSTimestamp']>, ParentType, ContextType>;
  webAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsListResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventsList'] = ResolversParentTypes['EventsList']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<Array<Maybe<ResolversTypes['Event']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowedUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['FollowedUser'] = ResolversParentTypes['FollowedUser']> = {
  followed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  followedAt?: Resolver<Maybe<ResolversTypes['AWSTimestamp']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeneralResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneralResponse'] = ResolversParentTypes['GeneralResponse']> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdResolvers<ContextType = any, ParentType extends ResolversParentTypes['Id'] = ResolversParentTypes['Id']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Invitation'] = ResolversParentTypes['Invitation']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['AWSTimestamp']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inviter?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  subjectNode?: Resolver<ResolversTypes['InvitationSubjectNode'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['InvitationType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitationSubjectNodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvitationSubjectNode'] = ResolversParentTypes['InvitationSubjectNode']> = {
  __resolveType: TypeResolveFn<'SportProgram' | 'Team', ParentType, ContextType>;
};

export type LikedVideoResolvers<ContextType = any, ParentType extends ResolversParentTypes['LikedVideo'] = ResolversParentTypes['LikedVideo']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  liked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  likedAt?: Resolver<Maybe<ResolversTypes['AWSTimestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  zipCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MatchResolvers<ContextType = any, ParentType extends ResolversParentTypes['Match'] = ResolversParentTypes['Match']> = {
  court?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['AWSTimestamp']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  schedule?: Resolver<Maybe<ResolversTypes['AWSTimestamp']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['AWSTimestamp']>, ParentType, ContextType>;
  teams?: Resolver<Array<ResolversTypes['Team']>, ParentType, ContextType>;
  videos?: Resolver<Array<Maybe<ResolversTypes['Content']>>, ParentType, ContextType>;
  winner?: Resolver<Maybe<ResolversTypes['Team']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addUserToWatchlistGroup?: Resolver<ResolversTypes['WatchlistGroup'], ParentType, ContextType, RequireFields<MutationAddUserToWatchlistGroupArgs, 'input'>>;
  blockUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationBlockUserArgs, 'id'>>;
  cancelSubscription?: Resolver<ResolversTypes['SubscriptionData'], ParentType, ContextType, RequireFields<MutationCancelSubscriptionArgs, 'subscriptionId'>>;
  cancelUserInviteInProgram?: Resolver<ResolversTypes['GeneralResponse'], ParentType, ContextType, RequireFields<MutationCancelUserInviteInProgramArgs, 'input'>>;
  checkInToEvent?: Resolver<ResolversTypes['CheckInToEventOutput'], ParentType, ContextType, RequireFields<MutationCheckInToEventArgs, 'input'>>;
  claimReward?: Resolver<ResolversTypes['GeneralResponse'], ParentType, ContextType, RequireFields<MutationClaimRewardArgs, 'input'>>;
  createComment?: Resolver<ResolversTypes['Comment'], ParentType, ContextType, RequireFields<MutationCreateCommentArgs, 'comment' | 'videoId'>>;
  createConversation?: Resolver<Maybe<Array<Maybe<ResolversTypes['Conversation']>>>, ParentType, ContextType, RequireFields<MutationCreateConversationArgs, 'input'>>;
  createDirectMessage?: Resolver<Maybe<ResolversTypes['DirectMessage']>, ParentType, ContextType, RequireFields<MutationCreateDirectMessageArgs, 'input'>>;
  createUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'input'>>;
  createWatchlistGroup?: Resolver<ResolversTypes['WatchlistGroup'], ParentType, ContextType, RequireFields<MutationCreateWatchlistGroupArgs, 'input'>>;
  deleteUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationDeleteUserArgs, never>>;
  deleteVideo?: Resolver<ResolversTypes['Id'], ParentType, ContextType, RequireFields<MutationDeleteVideoArgs, 'id'>>;
  deleteWatchlistGroup?: Resolver<ResolversTypes['WatchlistGroup'], ParentType, ContextType, RequireFields<MutationDeleteWatchlistGroupArgs, 'input'>>;
  followUser?: Resolver<ResolversTypes['FollowedUser'], ParentType, ContextType, RequireFields<MutationFollowUserArgs, 'id'>>;
  handleDocumentsRequest?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationHandleDocumentsRequestArgs, 'allow' | 'recruiterId' | 'requestId'>>;
  handleInvitationToProgram?: Resolver<ResolversTypes['GeneralResponse'], ParentType, ContextType, RequireFields<MutationHandleInvitationToProgramArgs, 'input'>>;
  handleInvitationToTeam?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationHandleInvitationToTeamArgs, 'input'>>;
  handleOrganizationOffer?: Resolver<ResolversTypes['Organization'], ParentType, ContextType, RequireFields<MutationHandleOrganizationOfferArgs, 'input'>>;
  inviteUserToProgram?: Resolver<ResolversTypes['GeneralResponse'], ParentType, ContextType, RequireFields<MutationInviteUserToProgramArgs, 'input'>>;
  inviteUserToTeam?: Resolver<ResolversTypes['GeneralResponse'], ParentType, ContextType, RequireFields<MutationInviteUserToTeamArgs, 'input'>>;
  likeVideo?: Resolver<ResolversTypes['LikedVideo'], ParentType, ContextType, RequireFields<MutationLikeVideoArgs, 'id'>>;
  pinWatchlistGroup?: Resolver<ResolversTypes['WatchlistGroup'], ParentType, ContextType, RequireFields<MutationPinWatchlistGroupArgs, 'input'>>;
  purchaseReward?: Resolver<ResolversTypes['PurchaseRewardOutput'], ParentType, ContextType, RequireFields<MutationPurchaseRewardArgs, 'input'>>;
  purchaseSubscription?: Resolver<ResolversTypes['SubscriptionData'], ParentType, ContextType, RequireFields<MutationPurchaseSubscriptionArgs, 'input'>>;
  readNotification?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationReadNotificationArgs, never>>;
  registerTeam?: Resolver<ResolversTypes['RegisterTeamOutput'], ParentType, ContextType, RequireFields<MutationRegisterTeamArgs, 'input'>>;
  removeUserFromProgram?: Resolver<ResolversTypes['GeneralResponse'], ParentType, ContextType, RequireFields<MutationRemoveUserFromProgramArgs, 'input'>>;
  removeUserFromWatchlistGroup?: Resolver<ResolversTypes['WatchlistGroup'], ParentType, ContextType, RequireFields<MutationRemoveUserFromWatchlistGroupArgs, 'input'>>;
  requestDocuments?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationRequestDocumentsArgs, 'athleteId'>>;
  trackShare?: Resolver<ResolversTypes['PostShare'], ParentType, ContextType, RequireFields<MutationTrackShareArgs, 'input'>>;
  trackView?: Resolver<ResolversTypes['View'], ParentType, ContextType, RequireFields<MutationTrackViewArgs, 'id' | 'type'>>;
  updateTeam?: Resolver<ResolversTypes['Team'], ParentType, ContextType, RequireFields<MutationUpdateTeamArgs, 'input'>>;
  updateUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'input'>>;
  uploadContent?: Resolver<ResolversTypes['Content'], ParentType, ContextType, RequireFields<MutationUploadContentArgs, never>>;
};

export type NotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = {
  action?: Resolver<ResolversTypes['NotificationAction'], ParentType, ContextType>;
  actor?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  actorTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSTimestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  receiverId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subjectNodeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationsListOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationsListOutput'] = ResolversParentTypes['NotificationsListOutput']> = {
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notifications?: Resolver<Array<Maybe<ResolversTypes['Notification']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  programs?: Resolver<Maybe<Array<Maybe<ResolversTypes['SportProgram']>>>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostShareResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostShare'] = ResolversParentTypes['PostShare']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProgramMember'] = ResolversParentTypes['ProgramMember']> = {
  inviteAccepted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchaseRewardOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchaseRewardOutput'] = ResolversParentTypes['PurchaseRewardOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scoreBoardDetails?: Resolver<ResolversTypes['ScoreBoardDetails'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  checkBlockedUsers?: Resolver<Array<Maybe<ResolversTypes['ID']>>, ParentType, ContextType>;
  getBlockedUsers?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType>;
  getComments?: Resolver<Array<Maybe<ResolversTypes['UserComment']>>, ParentType, ContextType, RequireFields<QueryGetCommentsArgs, 'videoId'>>;
  getConversationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryGetConversationIdArgs, 'firstUser' | 'secondUser'>>;
  getConversations?: Resolver<Maybe<Array<Maybe<ResolversTypes['Conversation']>>>, ParentType, ContextType>;
  getDirectMessages?: Resolver<Maybe<Array<Maybe<ResolversTypes['DirectMessage']>>>, ParentType, ContextType, RequireFields<QueryGetDirectMessagesArgs, 'pk'>>;
  getDocumentRequests?: Resolver<Array<Maybe<ResolversTypes['DocumentRequest']>>, ParentType, ContextType>;
  getEvents?: Resolver<ResolversTypes['EventsList'], ParentType, ContextType, RequireFields<QueryGetEventsArgs, never>>;
  getFollows?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType, RequireFields<QueryGetFollowsArgs, 'id' | 'type'>>;
  getInvitations?: Resolver<Array<Maybe<ResolversTypes['Invitation']>>, ParentType, ContextType>;
  getNotifications?: Resolver<ResolversTypes['NotificationsListOutput'], ParentType, ContextType, RequireFields<QueryGetNotificationsArgs, 'count'>>;
  getScoreBoardDetails?: Resolver<ResolversTypes['ScoreBoardDetails'], ParentType, ContextType>;
  getSubscriptionPrice?: Resolver<ResolversTypes['SubscriptionPrice'], ParentType, ContextType, RequireFields<QueryGetSubscriptionPriceArgs, 'input'>>;
  getTeamRegistrationPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  getTeams?: Resolver<ResolversTypes['TeamsList'], ParentType, ContextType, RequireFields<QueryGetTeamsArgs, never>>;
  getUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QueryGetUserArgs, 'id'>>;
  getUserToken?: Resolver<ResolversTypes['TokenUser'], ParentType, ContextType, RequireFields<QueryGetUserTokenArgs, 'id'>>;
  getVideoStats?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType, RequireFields<QueryGetVideoStatsArgs, 'id' | 'type'>>;
  getVideoViewers?: Resolver<ResolversTypes['UsersList'], ParentType, ContextType, RequireFields<QueryGetVideoViewersArgs, 'input'>>;
  getVideos?: Resolver<ResolversTypes['ContentList'], ParentType, ContextType, RequireFields<QueryGetVideosArgs, never>>;
  getWatchlistGroups?: Resolver<Array<Maybe<ResolversTypes['WatchlistGroup']>>, ParentType, ContextType, RequireFields<QueryGetWatchlistGroupsArgs, 'input'>>;
  isAllowedToViewDocuments?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryIsAllowedToViewDocumentsArgs, 'athleteId'>>;
  isProgramSubscribed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryIsProgramSubscribedArgs, 'input'>>;
  notifyUser?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryNotifyUserArgs, 'action' | 'subjectNodeId' | 'userId'>>;
  searchOrganizations?: Resolver<Array<Maybe<ResolversTypes['Organization']>>, ParentType, ContextType, RequireFields<QuerySearchOrganizationsArgs, 'input'>>;
  searchUsers?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType, RequireFields<QuerySearchUsersArgs, 'input'>>;
  verifyPurchase?: Resolver<ResolversTypes['SubscriptionData'], ParentType, ContextType, RequireFields<QueryVerifyPurchaseArgs, 'platform' | 'purchase'>>;
};

export type RegisterTeamOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegisterTeamOutput'] = ResolversParentTypes['RegisterTeamOutput']> = {
  paymentReceipt?: Resolver<Maybe<ResolversTypes['TeamRegistrationReceipt']>, ParentType, ContextType>;
  team?: Resolver<Maybe<ResolversTypes['Team']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RelativePersonInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['RelativePersonInfo'] = ResolversParentTypes['RelativePersonInfo']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  relation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RewardResolvers<ContextType = any, ParentType extends ResolversParentTypes['Reward'] = ResolversParentTypes['Reward']> = {
  costPoints?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantityPurchased?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScoreBoardDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScoreBoardDetails'] = ResolversParentTypes['ScoreBoardDetails']> = {
  engagementPoints?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rewards?: Resolver<Array<ResolversTypes['Reward']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SportResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sport'] = ResolversParentTypes['Sport']> = {
  class?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SportProgramResolvers<ContextType = any, ParentType extends ResolversParentTypes['SportProgram'] = ResolversParentTypes['SportProgram']> = {
  admin?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  division?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  members?: Resolver<Array<Maybe<ResolversTypes['ProgramMember']>>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType>;
  recruitGender?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sport?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  createDirectMessage?: SubscriptionResolver<Maybe<ResolversTypes['DirectMessage']>, "createDirectMessage", ParentType, ContextType, RequireFields<SubscriptionCreateDirectMessageArgs, 'pk'>>;
};

export type SubscriptionDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionData'] = ResolversParentTypes['SubscriptionData']> = {
  clientSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creationDate?: Resolver<Maybe<ResolversTypes['AWSTimestamp']>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['AWSTimestamp']>, ParentType, ContextType>;
  plan?: Resolver<Maybe<ResolversTypes['SubscriptionPlan']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['SubscriptionStatus']>, ParentType, ContextType>;
  subscriptionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionPrice'] = ResolversParentTypes['SubscriptionPrice']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Discount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamResolvers<ContextType = any, ParentType extends ResolversParentTypes['Team'] = ResolversParentTypes['Team']> = {
  coaches?: Resolver<Maybe<Array<Maybe<ResolversTypes['TeamMember']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  participated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  players?: Resolver<Maybe<Array<ResolversTypes['TeamMember']>>, ParentType, ContextType>;
  registeredAt?: Resolver<Maybe<ResolversTypes['AWSTimestamp']>, ParentType, ContextType>;
  registeredBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamMember'] = ResolversParentTypes['TeamMember']> = {
  checkedIn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  info?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  inviteSent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamRegistrationReceiptResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamRegistrationReceipt'] = ResolversParentTypes['TeamRegistrationReceipt']> = {
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamsListResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamsList'] = ResolversParentTypes['TeamsList']> = {
  items?: Resolver<Array<Maybe<ResolversTypes['Team']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TokenUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenUser'] = ResolversParentTypes['TokenUser']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TranscriptResolvers<ContextType = any, ParentType extends ResolversParentTypes['Transcript'] = ResolversParentTypes['Transcript']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  about?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  accolades?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  class?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubCoachInfo?: Resolver<Maybe<ResolversTypes['CoachInfo']>, ParentType, ContextType>;
  clubOrganization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currentSchool?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  engagementPoints?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  favoriteFood?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  favoriteHobby?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  favoriteSport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  favoriteSportToWatch?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  favoriteVacation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  followerCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  followingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  funFact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameFilm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameSchedule?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gpa?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  guardians?: Resolver<Maybe<Array<Maybe<ResolversTypes['RelativePersonInfo']>>>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  highSchoolCoachInfo?: Resolver<Maybe<ResolversTypes['CoachInfo']>, ParentType, ContextType>;
  hsGradYear?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isOnboarded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  jerseyNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newNotification?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Organization']>>>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pinnedInWatchlist?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profilePicture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  program?: Resolver<Maybe<ResolversTypes['SportProgram']>, ParentType, ContextType>;
  pushTokens?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  raceEthnicity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recruitGender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recruitSport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sports?: Resolver<Maybe<Array<Maybe<ResolversTypes['Sport']>>>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscription?: Resolver<Maybe<ResolversTypes['SubscriptionData']>, ParentType, ContextType>;
  tenure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainerInfo?: Resolver<Maybe<ResolversTypes['CoachInfo']>, ParentType, ContextType>;
  transcript?: Resolver<Maybe<ResolversTypes['Transcript']>, ParentType, ContextType>;
  viewCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  watched?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  watcherCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  watchingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserComment'] = ResolversParentTypes['UserComment']> = {
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSTimestamp'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  videoId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersListResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersList'] = ResolversParentTypes['UsersList']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ViewResolvers<ContextType = any, ParentType extends ResolversParentTypes['View'] = ResolversParentTypes['View']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  viewCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WatchlistGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['WatchlistGroup'] = ResolversParentTypes['WatchlistGroup']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['AWSTimestamp']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  members?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  membersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pinned?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pinnedAt?: Resolver<Maybe<ResolversTypes['AWSTimestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AWSEmail?: GraphQLScalarType;
  AWSTimestamp?: GraphQLScalarType;
  CheckInToEventOutput?: CheckInToEventOutputResolvers<ContextType>;
  CoachInfo?: CoachInfoResolvers<ContextType>;
  Comment?: CommentResolvers<ContextType>;
  Content?: ContentResolvers<ContextType>;
  ContentList?: ContentListResolvers<ContextType>;
  ContentOwner?: ContentOwnerResolvers<ContextType>;
  Conversation?: ConversationResolvers<ContextType>;
  DirectMessage?: DirectMessageResolvers<ContextType>;
  Discount?: DiscountResolvers<ContextType>;
  DocumentRequest?: DocumentRequestResolvers<ContextType>;
  Event?: EventResolvers<ContextType>;
  EventsList?: EventsListResolvers<ContextType>;
  FollowedUser?: FollowedUserResolvers<ContextType>;
  GeneralResponse?: GeneralResponseResolvers<ContextType>;
  Id?: IdResolvers<ContextType>;
  Invitation?: InvitationResolvers<ContextType>;
  InvitationSubjectNode?: InvitationSubjectNodeResolvers<ContextType>;
  LikedVideo?: LikedVideoResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  Match?: MatchResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  NotificationsListOutput?: NotificationsListOutputResolvers<ContextType>;
  Organization?: OrganizationResolvers<ContextType>;
  PostShare?: PostShareResolvers<ContextType>;
  ProgramMember?: ProgramMemberResolvers<ContextType>;
  PurchaseRewardOutput?: PurchaseRewardOutputResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RegisterTeamOutput?: RegisterTeamOutputResolvers<ContextType>;
  RelativePersonInfo?: RelativePersonInfoResolvers<ContextType>;
  Reward?: RewardResolvers<ContextType>;
  ScoreBoardDetails?: ScoreBoardDetailsResolvers<ContextType>;
  Sport?: SportResolvers<ContextType>;
  SportProgram?: SportProgramResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SubscriptionData?: SubscriptionDataResolvers<ContextType>;
  SubscriptionPrice?: SubscriptionPriceResolvers<ContextType>;
  Team?: TeamResolvers<ContextType>;
  TeamMember?: TeamMemberResolvers<ContextType>;
  TeamRegistrationReceipt?: TeamRegistrationReceiptResolvers<ContextType>;
  TeamsList?: TeamsListResolvers<ContextType>;
  TokenUser?: TokenUserResolvers<ContextType>;
  Transcript?: TranscriptResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserComment?: UserCommentResolvers<ContextType>;
  UsersList?: UsersListResolvers<ContextType>;
  View?: ViewResolvers<ContextType>;
  WatchlistGroup?: WatchlistGroupResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  aws_api_key?: Aws_Api_KeyDirectiveResolver<any, any, ContextType>;
  aws_auth?: Aws_AuthDirectiveResolver<any, any, ContextType>;
  aws_cognito_user_pools?: Aws_Cognito_User_PoolsDirectiveResolver<any, any, ContextType>;
  aws_iam?: Aws_IamDirectiveResolver<any, any, ContextType>;
  aws_lambda?: Aws_LambdaDirectiveResolver<any, any, ContextType>;
  aws_oidc?: Aws_OidcDirectiveResolver<any, any, ContextType>;
  aws_publish?: Aws_PublishDirectiveResolver<any, any, ContextType>;
  aws_subscribe?: Aws_SubscribeDirectiveResolver<any, any, ContextType>;
  defer?: DeferDirectiveResolver<any, any, ContextType>;
};
