import moment from "moment";
import { useNavigate } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { SelectField } from "@aws-amplify/ui-react";

import {
  getEvents,
  getTeams,
  updateSelectedEventId,
} from "../../redux/features/team.slice";
import { PAGES } from "../../app-config";
import Table from "../../components/table";
import Button from "../../components/button";
import Loader from "../../components/loader";
import { DIVISION_LIST } from "../../util/constants";
import { useAppDispatch, useAppSelector } from "../../redux/store";

const Root = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const showDialog = useAppSelector((s) => s.alerts.showDialog);
  const user = useAppSelector((s) => s.auth.userData);
  const teams = useAppSelector((s) => s.team.teamsList.items);
  const events = useAppSelector((s) => s.team.eventsList.items);
  const getTeamsInput = useAppSelector((s) => s.team.teamsList.input);
  const loadingGetTeams = useAppSelector((s) => s.team.fetchingStatus.getTeams);
  const [selectedEvent, setSelectedEvent] = useState("");

  useEffect(() => {
    if (!loadingGetTeams && !teams) {
      console.log("useeffects in side root called 1");
      handleGetEvent();
      dispatch(getTeams({ input: getTeamsInput }));
    }
  }, []);

  const handleGetEvent = async () => {
    const { err, res } = await new Promise<{ err; res }>((resolve, reject) => {
      dispatch(
        getEvents({
          input: {
            eventId: "",
          },
        })
      );
    });
    if (res) {
    }
    if (err)
      showDialog(
        "error",
        "Unable to fetch events",
        err || "Something went wrong."
      );
  };

  const handlePageChange = (pgNo: number, pgSize: number) => {
    if (
      getTeamsInput.page?.pgNo === pgNo &&
      getTeamsInput.page.pgSize === pgSize
    ) {
      return;
    }
    dispatch(getTeams({ input: { ...getTeamsInput, page: { pgNo, pgSize } } }));
  };

  const disableRegisterButton = () => {
    return ![DIVISION_LIST[0], DIVISION_LIST[1]].includes(user?.class!);
  };

  const filterEventsByUserSport = (events) => {
    return events.filter((event) => event.sport === user?.recruitSport);
  };

  const handleEventSelection = (event) => {
    setSelectedEvent(event.target.value);
    dispatch(updateSelectedEventId(event.target.value));
  };

  return (
    <div>
      {teams?.length ? (
        <Table
          label={<div className="font-bold mb-3">Your Registered Teams</div>}
          columns={[
            { accessKey: "name", label: "Team Name" },
            {
              accessKey: "registeredAt",
              label: "Registered At",
              format: (v) => moment(v).format("DD MMM YYYY, h:mm:ss a"),
            },
            {
              accessKey: "players",
              label: "No Of Athletes",
              format: (_, { players }) => players?.length || 0,
            },
            {
              accessKey: "players",
              label: "Existing Accounts",
              format: (_, { players }) =>
                players?.filter((p) => p.info.isOnboarded).length || 0,
            },
            {
              accessKey: "coaches",
              label: "No of Coaches",
              format: (_, { coaches }) => coaches?.length || 0,
            },
          ]}
          rows={teams}
          dataFetchingAsync
          onClickRow={(_, __, ___, { id }) =>
            navigate(`/${PAGES.home.children.teams.path}/${id}`)
          }
          pageNumber={getTeamsInput.page?.pgNo || undefined}
          pageSize={getTeamsInput.page?.pgSize || undefined}
          onPaginationChange={handlePageChange}
        />
      ) : loadingGetTeams ? (
        <Loader open={loadingGetTeams} />
      ) : (
        <div>
          {filterEventsByUserSport(events).length > 0 ? (
            <>
              <div className="w-max">
                <SelectField
                  label="Events"
                  descriptiveText="Please select an event to register your team"
                  placeholder="Choose an event"
                  onChange={handleEventSelection}
                  style={{ flex: 0.5 }}
                >
                  {filterEventsByUserSport(events).map((e) => (
                    <option key={e.id} value={e.id}>
                      {e.name}
                    </option>
                  ))}
                </SelectField>
              </div>
              <div className="text-xl font-semibold p-4">
                {disableRegisterButton()
                  ? 'Only coaches with divisions "Club" and "Highschool" can register for a team'
                  : "You have no team registered yet"}
              </div>
            </>
          ) : (
            <div className="text-xl font-semibold p-4">
              No events are taking place right now for the sport you selected.
            </div>
          )}
          <Button
            color="secondary"
            onClick={() => {
              navigate(`/${PAGES.home.children.registration.path}`);
            }}
            disabled={
              disableRegisterButton() ||
              !selectedEvent ||
              filterEventsByUserSport(events).length === 0
            }
          >
            Register a new team
          </Button>
        </div>
      )}
    </div>
  );
};

export default memo(Root);
