import { FC, useState } from "react";

import { useAppDispatch } from "../../../../redux/store";
import aboutYou from "../../../../assets/icons/aboutYou.svg";
import { updateCreateUserData } from "../../../../redux/features/auth.slice";

const AboutYou: FC = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const dispatch = useAppDispatch();

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
    dispatch(updateCreateUserData({ firstName: e.target.value }));
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
    dispatch(updateCreateUserData({ lastName: e.target.value }));
  };

  return (
    <div className="w-full max-w-lg">
      <img src={aboutYou} alt="aboutYou" />
      <h2 className="text-left mb-6 mt-5 font-bold text-twentyEight">
        Tell us about you
      </h2>
      <div className=" rounded-xl w-full max-w-lg bg-surface-fill">
        <form>
          <div className="border-b-2">
            <input
              className=" appearance-none rounded-xl w-full p-4 text-gray-500 leading-tight bg-transparent"
              id="firstName"
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={handleFirstNameChange}
            />
          </div>
          <div className="">
            <input
              className=" appearance-none rounded-xl w-full p-4 text-gray-500 leading-tight bg-transparent"
              id="lastName"
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={handleLastNameChange}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AboutYou;
