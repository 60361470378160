import { configureStore, combineReducers } from '@reduxjs/toolkit';

import authReducer from "./features/auth.slice";
import teamReducer from "./features/team.slice";
import alertsReducer from "./features/alerts.slice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const parentReducer = combineReducers({
  auth: authReducer,
  team: teamReducer,
  alerts: alertsReducer,
});

const store = configureStore({
  reducer: parentReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
})
export default store;

export type AppDispatch = typeof store.dispatch;
export type RootStateType = ReturnType<typeof parentReducer>

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootStateType> = useSelector

