import { useState } from 'react'
import { useParams } from "react-router-dom";

import Table from '../../components/table';
import Button from '../../components/button';
import Spinner from '../../components/spinner';
import { TeamMember, User } from '../../graphql/schemaTypes';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { inviteUserToTeam, updateTeam } from '../../redux/features/team.slice';
import TeamForm, { FormFields as TeamFormFields } from '../registration/components/TeamForm';

const TeamDetail = () => {
    const dispatch = useAppDispatch();
    const { teamId } = useParams<{ teamId?: string }>();
  const [isUpdate, setIsUpdate] = useState(false);
  const [loadingById, setLoadingById] = useState({});
  const team = useAppSelector(s => s.team.teamsList.items?.find(v => v.id === teamId))
  
    const showAlert = useAppSelector(s => s.alerts.showAlert);
    const showDialog = useAppSelector(s => s.alerts.showDialog);

    if (!team)
        return (
            <div className='text-2xl font-semibold' >
                Team not found 😢
            </div>
        )

    const handleUpdateTeam = async ({ coaches, name, players }: TeamFormFields) => {
        const { err, res } = await new Promise<{ err, res }>((resolve, reject) => {
            dispatch(updateTeam({
                input: {
                    name, coaches, players, teamId: team.id
                },
                callback: (err, res) => {
                    resolve({ err, res })
                }
            }))
        });

        if (res) {
            showAlert({ message: "Team Updated", severity: 'success' })
            setIsUpdate(false);
        }
        if (err)
            showDialog("error", "Team Update Error", err || "Something went wrong.",)
    }

  const handleResendInvite = async (inviteeId: string, info?: User) => {
    setLoadingById({ ...loadingById, [inviteeId]: true });
    const { err, res } = await new Promise<{ err; res }>((resolve, reject) => {
      dispatch(
        inviteUserToTeam({
          input: {
            inviteeId,
            teamId: team.id,
          },
          callback: (err, res) => {
            resolve({ err, res });
          },
        })
      );
    });
    if (res) {
      const alertMessage = info?.isOnboarded
        ? "Notification has been sent successfully"
        : res.message;
      showAlert({ message: alertMessage, severity: "success" });
    }
    if (err) {
      showDialog("error", "Team Invite Error", err || "Something went wrong.");
    }
    setLoadingById({ ...loadingById, [inviteeId]: false });
  };

    if (isUpdate) {
        return (
            <div>
                <Button size='small' onClick={() => setIsUpdate(false)}  >Cancel</Button>
                <br /> <br />
                <TeamForm type='Update'
                    initialValues={{
                        name: team.name,
                        coaches: team.coaches?.map(v => ({
                            id: v?.info.id, firstName: v?.info.firstName, lastName: v?.info.lastName,
                            email: v?.info.email, phoneNumber: v?.info.phoneNumber, isOnboarded: v?.info.isOnboarded
                        })) || [],
                        players: team.players?.map(v => ({
                            id: v?.info.id, firstName: v?.info.firstName, lastName: v?.info.lastName,
                            email: v?.info.email, hsGradYear: v?.info.hsGradYear, position: v?.info.position,
                            height: v?.info.height, address: v?.info.address, city: v?.info.city, isOnboarded: v?.info.isOnboarded,
                            state: v?.info.state, zipCode: v?.info.zipCode, jerseyNumber: v?.info.jerseyNumber, phoneNumber: v?.info.phoneNumber, weight: v?.info.weight
                        })) || [],
                    }}
                    onSubmit={handleUpdateTeam}
                />
            </div >
        )
    }


    return (
      <div>
        <Button
          size="small"
          color="secondary"
          className="float-right"
          onClick={() => setIsUpdate(true)}
        >
          Update Team Info
        </Button>
        <h1 className="text-xl font-semibold mb-1">Team Name: {team.name}</h1>

        <h2 className="text-lg font-semibold">Coach Information</h2>
        <div className="text-sm overflow-x-auto">
          <Table
            rows={(team.coaches as TeamMember[]) || []}
            columns={[
              {
                accessKey: "info",
                label: "First Name",
                format: (_, { info }) => info.firstName,
              },
              {
                accessKey: "info",
                label: "Last Name",
                format: (_, { info }) => info.lastName,
              },
              {
                accessKey: "info",
                label: "Email",
                format: (_, { info }) => info.email,
              },
              {
                accessKey: "info",
                label: "Cell #",
                format: (_, { info }) => info.phoneNumber,
              },
              {
                accessKey: "info",
                label: "Existing Account",
                format: (_, { info }) => (info.isOnboarded ? "Yes" : "No"),
              },
              {
                accessKey: "inviteSent",
                minWidth: 150,
                label: "Actions",
                format: (_, { inviteSent, info }) => {
                  const isLoading = loadingById[info.id];
                  return !info.isOnboarded ? (
                    <Button
                      onClick={() => handleResendInvite(info.id)}
                      color="secondary"
                      size="small"
                      disabled={isLoading}
                    >
                      {inviteSent ? "Resend Invite" : "Invite"}
                      {isLoading && <Spinner className="ml-2" h={15} w={15} />}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleResendInvite(info.id, info)}
                      color="ternary"
                      size="small"
                      disabled={isLoading}
                    >
                      {inviteSent ? "Notify Again" : "Notify"}
                      {isLoading && <Spinner className="ml-2" h={15} w={15} />}
                    </Button>
                  );
                },
              },
            ]}
            hideFooter
          />
        </div>

        <br />

        <h2 className="text-lg font-semibold">Athlete Information</h2>
        <div className="text-sm overflow-x-auto">
          <Table
            rows={(team.players as TeamMember[]) || []}
            columns={[
              {
                accessKey: "info",
                minWidth: 150,
                label: "First Name",
                format: (_, { info }) => info.firstName,
              },
              {
                accessKey: "info",
                minWidth: 150,
                label: "Last Name",
                format: (_, { info }) => info.lastName,
              },
              {
                accessKey: "info",
                label: "Grad Year",
                format: (_, { info }) => info.hsGradYear,
              },
              {
                accessKey: "info",
                label: "Weight",
                format: (_, { info }) => info.weight,
              },
              {
                accessKey: "info",
                label: "POS",
                format: (_, { info }) => info.position,
              },
              {
                accessKey: "info",
                label: "Height",
                format: (_, { info }) => info.height,
              },
              {
                accessKey: "info",
                minWidth: 100,
                label: "Jersey #",
                format: (_, { info }) => info.jerseyNumber,
              },
              {
                accessKey: "info",
                minWidth: 150,
                label: "City",
                format: (_, { info }) => info.city,
              },
              {
                accessKey: "info",
                minWidth: 150,
                label: "State",
                format: (_, { info }) => info.state,
              },
              {
                accessKey: "info",
                minWidth: 150,
                label: "Zip Code",
                format: (_, { info }) => info.zipCode,
              },
              {
                accessKey: "info",
                minWidth: 250,
                label: "Address",
                format: (_, { info }) => info.address,
              },
              {
                accessKey: "info",
                minWidth: 150,
                label: "Existing Account",
                format: (_, { info }) => (info.isOnboarded ? "Yes" : "No"),
              },
              {
                accessKey: "info",
                minWidth: 150,
                label: "Cell #",
                format: (_, { info }) => info.phoneNumber,
              },
              {
                accessKey: "info",
                minWidth: 150,
                label: "Email",
                format: (_, { info }) => info.email,
              },
              {
                accessKey: "inviteSent",
                minWidth: 150,
                label: "Actions",
                format: (_, { inviteSent, info }) => {
                  const isLoading = loadingById[info.id];
                  return !info.isOnboarded ? (
                    <Button
                      onClick={() => handleResendInvite(info.id)}
                      color="secondary"
                      size="small"
                      disabled={isLoading}
                    >
                      {inviteSent ? "Resend Invite" : "Invite"}
                      {isLoading && <Spinner className="ml-2" h={15} w={15} />}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleResendInvite(info.id, info)}
                      color="ternary"
                      size="small"
                      disabled={isLoading}
                    >
                      {inviteSent ? "Notify Again" : "Notify"}
                      {isLoading && <Spinner className="ml-2" h={15} w={15} />}
                    </Button>
                  );
                },
              },
            ]}
            hideFooter
          />
        </div>
      </div>
    );
}

export default TeamDetail