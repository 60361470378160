import {
  InputUser,
  InviteUserToTeamInput,
  Mutation,
  MutationCreateUserArgs,
  MutationInviteUserToTeamArgs,
  MutationRegisterTeamArgs,
  MutationUpdateTeamArgs,
} from "./schemaTypes";
import { gqlQueryHandler } from "./utils";

export const createUser = async (input: InputUser) => {
  return await gqlQueryHandler<
    MutationCreateUserArgs,
    { createUser: Mutation["createUser"] }
  >({
    query: /* GraphQL */ `
      mutation CreateUser($input: InputUser!) {
        createUser(input: $input) {
          id
          email
          phoneNumber
          firstName
          lastName
          role
          state
          city
          zipCode
          profilePicture
          gender
          dateOfBirth
          height
          weight
          hsGradYear
          raceEthnicity
          sports {
            sport
            class
            position
          }
          nickname
          class
          organization {
            name
            zipCode
            city
            state
          }
          program {
            id
            sport
            division
            recruitGender
            seats
            admin {
              firstName
              lastName
              id
              email
              title
              profilePicture
            }
            members {
              user {
                firstName
                lastName
                id
                email
                title
                profilePicture
              }
              inviteAccepted
            }
          }
          subscription {
            clientSecret
            creationDate
            expirationDate
            plan
            price
            status
            subscriptionId
          }
          class
          recruitGender
          recruitSport
          title
          tenure
          bio
          gpa
          about
          pushTokens
          followed
          followerCount
          followingCount
          viewCount
          favoriteSport
          isOnboarded
        }
      }
    `,
    variables: { input },
  });
};

export const registerTeam = async ({ input }: MutationRegisterTeamArgs) => {
  return await gqlQueryHandler<
    MutationRegisterTeamArgs,
    { registerTeam: Mutation["registerTeam"] }
  >({
    query: /* GraphQL */ `
      mutation MyMutation($input: RegisterTeamInput!) {
        registerTeam(input: $input) {
          team {
            id
            name
            registeredAt
            score
            registeredBy {
              id
              role
              email
              phoneNumber
              firstName
              lastName
              profilePicture
              isOnboarded
            }
            coaches {
              info {
                id
                role
                email
                phoneNumber
                firstName
                lastName
                profilePicture
                isOnboarded
              }
              inviteSent
              checkedIn
            }
            players {
              info {
                id
                role
                email
                phoneNumber
                firstName
                lastName
                profilePicture
                isOnboarded
                jerseyNumber
                hsGradYear
                position
                height
                address
                city
                state
                zipCode
              }
              inviteSent
              checkedIn
            }
          }
          paymentReceipt {
            price
            clientSecret
          }
        }
      }
    `,
    variables: { input },
  });
};

export const updateTeam = async ({ input }: MutationUpdateTeamArgs) => {
  return await gqlQueryHandler<
    MutationUpdateTeamArgs,
    { updateTeam: Mutation["updateTeam"] }
  >({
    query: /* GraphQL */ `
      mutation MyMutation($input: UpdateTeamInput!) {
        updateTeam(input: $input) {
          id
          name
          registeredAt
          score
          registeredBy {
            id
            role
            email
            phoneNumber
            firstName
            lastName
            profilePicture
            isOnboarded
          }
          coaches {
            info {
              id
              role
              email
              phoneNumber
              firstName
              lastName
              profilePicture
              isOnboarded
            }
            inviteSent
            checkedIn
          }
          players {
            info {
              id
              role
              email
              phoneNumber
              firstName
              lastName
              profilePicture
              isOnboarded
              jerseyNumber
              hsGradYear
              position
              height
              weight
              address
              city
              state
              zipCode
            }
            inviteSent
            checkedIn
          }
        }
      }
    `,
    variables: { input },
  });
};

export const inviteUserToTeam = async (input: InviteUserToTeamInput) => {
  return await gqlQueryHandler<
    MutationInviteUserToTeamArgs,
    { inviteUserToTeam: Mutation["inviteUserToTeam"] }
  >({
    query: /* GraphQL */ `
      mutation MyMutation($input: InviteUserToTeamInput!) {
        inviteUserToTeam(input: $input) {
          message
          data
        }
      }
    `,
    variables: { input },
  });
};
