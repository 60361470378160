import { useRoutes } from "react-router-dom";

import Home from "./pages/Home";
import Auth from "./pages/Auth";
import { PAGES } from "./app-config";
import Onboarding from "./pages/onboarding";
import ProtectedRoute from "./protectedRoutes";
import RegistrationPage from "./pages/registration";
import Teams, { TeamsRoot, Teams_TeamDetail } from "./pages/teams";

export default function Routes() {
  return useRoutes([
    {
      path: PAGES.home.path,
      element: (
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      ),
      children: [
        {
          path: PAGES.home.children.registration.path,
          element: <RegistrationPage />,
        },
        {
          path: PAGES.home.children.teams.path,
          element: <Teams />,
          children: [
            { path: "", element: <TeamsRoot /> },
            { path: ":teamId", element: <Teams_TeamDetail /> },
          ],
        },
      ],
    },
    {
      path: PAGES.auth.path,
      element: <Auth />,
    },
    {
      path: PAGES.onboard.path,
      element: <Onboarding />,
    },
  ]);
}
