import { FC, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useAppSelector } from "../redux/store";
import { PAGES } from "../app-config";

const Home: FC = () => {
    const navigate = useNavigate();
    const userData = useAppSelector((s) => s.auth.userData);

    // useEffect(() => {
    //     console.log("calling home use effect");
    //     if (userData?.isOnboarded && window.location.pathname !== PAGES.home.children.teams.title) {
    //         navigate(`/${PAGES.home.children.teams.path}`);
    //     }
    // }, []);

    useEffect(() => {
        // console.log("calling home use effect");
        if (userData?.isOnboarded && window.location.pathname === "/") {
            navigate(`/${PAGES.home.children.teams.path}`);
        }
    }, [userData, navigate]);

    return (
        <div className="p-5">
            <Outlet />
        </div>
    );
};

export default Home;
