import * as Auth from "aws-amplify/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { getUser } from "../../../graphql/queries";
import { createUser } from "../../../graphql/mutations";
import { InputUser } from "../../../graphql/schemaTypes";

export const signOut = createAsyncThunk(
  "amplify/signout",
  async (data, thunkAPI) => {
    return await Auth.signOut();
  }
);

export const loadAuthenticatedUser = createAsyncThunk(
    'amplify/loadAuthenticatedUser',
    async ({ forceRefresh }: { forceRefresh?: boolean } | undefined = {}, thunkAPI) => {
        try {
            // Auth.status
            const res = await Auth.fetchAuthSession({ forceRefresh });
            const userId = res.tokens?.idToken?.payload["custom:userId"]?.toString()!;
            const { data } = await getUser(userId);
            return data.getUser;

        } catch (err) {
            const error: any = err;
            console.log("loadAuthenticatedUser_Error", err)
            if (error.errors?.[0]?.message?.includes("User not found")) {
                return null
            }
            throw err;
        }
    }
);

export const createUserMutation = createAsyncThunk(
  "mutation/createuser",
  async ({ input }: { input: InputUser }, thunkAPI) => {
    try {
      const { data } = await createUser(input);
      return data.createUser;
    } catch (err) {
      console.log("createUserMutation_Error", err);
      const error: any = err;
      throw error.errors?.[0]?.message;
    }
  }
);
