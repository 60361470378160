import { FC } from "react";

import { getAddress } from "../../../../../util/onboarding";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { updateCreateUserData } from "../../../../../redux/features/auth.slice";

const OrganisationLocation: FC = () => {
  const dispatch = useAppDispatch();
  const createUserData = useAppSelector((s) => s.auth.createUserData);
  const { organization } = createUserData || {};
  const { zipCode, state, city } = organization || {};
  const isZipCodeFieldInitiallyDisabled = Boolean(zipCode && state && city);

  const handleZipcodeChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newZipcode = event.target.value;
    updateValue({ organization: { ...organization, zipCode: newZipcode } });
    if (newZipcode.length === 5) {
      const address = await getAddress(newZipcode);
      if (address && address.length === 2) {
        updateValue({
          organization: {
            ...organization,
            zipCode: newZipcode,
            state: address[0],
            city: address[1],
          },
        });
      }
    } else {
      updateValue({
        organization: {
          ...organization,
          zipCode: newZipcode,
          state: "",
          city: "",
        },
      });
    }
  };

  const updateValue = (payload) => {
    dispatch(updateCreateUserData(payload));
  };

  return (
    <div className="w-full max-w-lg mt-8">
      <p className="text-left mb-6 ml-5 text-secondary text-xs">
        ORGANISATION LOCATION
      </p>
      <div className=" rounded-xl w-full max-w-lg bg-surface-fill">
        <form>
          <div className="flex flex-row border-b-2">
            <p className="w-full p-4">Zip Code</p>
            <input
              className=" appearance-none rounded-xl w-full p-4 text-gray-500 leading-tight text-right bg-transparent"
              id="zipcode"
              type="text"
              placeholder="12345"
              maxLength={5}
              value={zipCode!! || ''}
              disabled={isZipCodeFieldInitiallyDisabled}
              onChange={handleZipcodeChange}
            />
          </div>
          <div className=" flex flex-row border-b-2">
            <p className="w-full p-4">State</p>
            <input
              className=" appearance-none rounded-xl w-full p-4 text-gray-500 leading-tight bg-transparent text-right"
              id="state"
              type="text"
              placeholder="State"
              disabled
              value={state!!}
            />
          </div>
          <div className="flex">
            <p className="w-full p-4">City</p>
            <input
              className=" appearance-none rounded-xl w-full p-4 text-gray-500 leading-tight bg-transparent text-right"
              id="city"
              type="text"
              placeholder="City"
              disabled
              value={city!!}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default OrganisationLocation;
