import { Text } from "@aws-amplify/ui-react";

const PasswordText = () => {
  return (
    <Text variation="secondary" fontSize="smaller" color={"GrayText"}>
      Password must be at least 8 characters, including a lowercase letter,
      uppercase letter, number, and special character (@$!%*?&).
    </Text>
  );
};

export default PasswordText;
