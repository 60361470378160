
const home = {
  title: "App",
  path: "/",
  children: {
    registration: {
      title: "Registration",
      path: "registration",
    },
    teams: {
      title: "Teams",
      path: "teams",
    },
  },
};

const auth = {
    title: "Auth",
    path: "auth"
}

const onboard = {
    title: "Onboard",
    path: "onboard"
}

export {
    home,
    auth,
    onboard
}