import { HTMLProps, memo } from "react";
import { Form, Formik, FormikProps, useField, ErrorMessageProps, Field, FormikHelpers, ErrorMessage } from 'formik';


export const FormErrorField = ({ name, ...others }: ErrorMessageProps) => {
    return (
        <ErrorMessage name={name} component={({ children: c }: any) => <span className={`text-red-700 text-xs`} >{c}</span>} {...others} />
    )
}

export const FormInputField = memo(({ type, name, className, label, required, onChange }: HTMLProps<HTMLInputElement>) => {
    const [field, meta, helpers] = useField({ name: name! });
    // name === "schedule" && console.log(`field.${name} ==>`, field)
    // name === "schedule" && console.log(`meta.${name} ==>`, meta)
    return (
        <div key={name} className={`${className} `}>
            <span>{`${label}${(required) ? '*' : ''}`}</span>
            <input onBlur={field.onBlur} type={type} defaultValue={meta.value} name={name} onChange={onChange || field.onChange} required={required} />
            <FormErrorField name={name!} />
        </div>
    )
})

export const FormSelectField = memo(({ name, className, label, required, options }: HTMLProps<HTMLInputElement> & { options: SelectFieldInterface['options'] }) => {
    const [field, meta, helpers] = useField({ name: name! })
    return (
        <div key={name} className={`${className} `}>
            <span>{`${label}${(required) ? '*' : ''}`}</span>
            <select onBlur={field.onBlur} value={meta.value} name={name} onChange={field.onChange} required={required} >
                <option value=''></option>
                {options.map((o, idx) => {
                    return <option key={idx} value={o.value}>{o.label}</option>
                })}
            </select>
            <FormErrorField name={name!} />
        </div>
    )
})


/**
 * 
 * @param param0 e = accepts change event of input element
 * @param param1 handleChange = accepts the onChange handler
 * @param param2 includs = accepts an array of characters that need to be include
 * @param param3 formatting = accepts boolean, if true than if will return "1,000" else "1000"
 *                      number formatting can only be possible if the include array is empty
 */
const numFrmt = new Intl.NumberFormat();
export const filterNumber = ({ e, handleChange, include = [], formating = false }: {
    e: React.ChangeEvent<HTMLInputElement>, handleChange: (e: React.ChangeEvent) => void, include?: string[], formating?: boolean,
}) => {
    const filterChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ...include];

    let value: number | string = e.target.value.split('').filter((chr) => filterChars.includes(chr)).join('');

    if (formating) {
        const _num = Number(value);
        if (isNaN(_num)) { value = _num }
        else { value = numFrmt.format(_num) }
    }

    e.target.value = value.toString();
    handleChange(e)
}



export interface FieldInterface {
    name: string;
    type: "button" | "color" | "date" | "custom" | "text-note" | "datetime-local" | "text-area" | "email" | "file" | "hidden" | "image" | "month" | "number" | "semi-number" | "password" | "radio" | "range" | "reset" | "search" | "submit" | "tel" | "text" | "time" | "url" | "week";
    label: string
    notRequried?: boolean;
    value?: string; // default value
    fieldArea?: 1 | 2 | 3 | 4
}

export interface FieldGroupInterface extends Omit<FieldInterface, 'type'> {
    type: "fieldGroup"
    /**
     * only works if "type" is "fieldGroup"
     */
    fieldGroup: CombineFieldInterface[];
}

export interface SelectFieldInterface extends Omit<FieldInterface, 'type'> {
    type: "select" | "checkbox"
    /**
     * only works if "type" is "select" | "checkbox"
     */
    options: { value: string, label: string }[];
}

export type CombineFieldInterface = FieldInterface | FieldGroupInterface | SelectFieldInterface
